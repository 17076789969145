@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/leaflet/dist/leaflet.css";
html, body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-hidden {
  overflow: hidden !important;
}

.h-300p {
  height: 300px;
}

.h-400p {
  height: 400px;
}

.transition {
  transition: 0.2s;
}

.white {
  color: #fff;
}

.mat-card-header-text {
  margin: 0 8px !important;
}

.draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.page-wrapper.mat-drawer-container {
  z-index: unset;
  min-height: calc(100% - (56px + 56px));
}
.page-wrapper.mat-drawer-container .mat-drawer-content {
  overflow: hidden;
  min-height: 450px;
  min-height: calc(100vh - (56px + 56px));
}

.toolbar-dropdown-menu.mat-menu-panel {
  margin-top: 9px;
  border-radius: 0 !important;
  overflow: hidden;
}
.toolbar-dropdown-menu.mat-menu-panel .mat-menu-content {
  padding: 0;
}
.toolbar-dropdown-menu.mat-menu-panel .ps__rail-y {
  left: auto !important;
}

.logo {
  font-weight: 700;
  text-decoration: none;
  min-width: calc(260px - 16px);
  color: #fff;
}
.logo img {
  width: 34px;
}
.logo.mobile {
  min-width: calc(66px - 16px);
}
.logo.mobile span {
  display: none;
}

.sidenav .mat-drawer-inner-container {
  overflow: hidden;
}

.app {
  height: 100%;
  overflow: hidden;
}
.app.fixed-header .page-wrapper.mat-drawer-container {
  top: 56px;
  padding-bottom: 56px;
  min-height: calc(100% - 56px);
}
.app.fixed-header .header {
  position: fixed;
  z-index: 999;
  width: 100%;
}
.app.fixed-header .sidenav {
  padding-bottom: 56px;
}
.app.fixed-header.fixed-sidenav .sidenav {
  padding-bottom: 0;
}
.app.fixed-sidenav .sidenav {
  position: fixed;
  z-index: 3;
}
.app.fixed-sidenav .sidenav .sidenav-menu-outer {
  height: calc(100% - 56px);
}
.app.fixed-sidenav .footer {
  transition: 0.3s;
  width: calc(100% - 260px);
  float: right;
}
.app.fixed-sidenav .footer.full-width {
  width: 100%;
}
.app.fixed-sidenav.compact .footer {
  width: calc(100% - 170px);
}
.app.fixed-sidenav.compact .footer.full-width {
  width: 100%;
}
.app.fixed-sidenav.mini .footer {
  width: calc(100% - 66px);
}
.app.fixed-sidenav.mini .footer.full-width {
  width: 100%;
}
.app.fixed-footer .page-wrapper.mat-drawer-container {
  padding-bottom: 56px;
}
.app.fixed-footer .sidenav {
  padding-bottom: 56px;
}
.app.fixed-footer .footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
.app.fixed-footer.fixed-sidenav .sidenav {
  padding-bottom: 0;
  z-index: 4;
}
.app.compact .logo {
  min-width: calc(170px - 16px);
}
.app.compact .sidenav {
  width: 170px;
}
.app.compact .user-block img {
  width: 90px;
  height: 90px;
}
.app.compact .menu-item .mat-button {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.app.compact .menu-item .mat-button .menu-icon {
  margin: 0;
}
.app.compact .menu-item .mat-button .menu-title {
  line-height: 24px;
}
.app.compact .menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.compact .menu-expand-icon {
  top: 28px;
}
.app.compact .sub-menu .mat-button {
  padding-left: 0 !important;
}
.app.mini .logo {
  min-width: calc(66px - 16px);
}
.app.mini .logo span {
  display: none;
}
.app.mini .sidenav {
  width: 66px;
}
.app.mini .user-block img {
  width: 45px;
  height: 45px;
}
.app.mini .menu-item .mat-button {
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 66px;
}
.app.mini .menu-item .mat-button .menu-icon {
  margin: 0;
}
.app.mini .menu-item .mat-button .menu-title {
  line-height: 24px;
}
.app.mini .menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.mini .menu-item .menu-title {
  display: none;
}
.app.mini .menu-expand-icon {
  top: 14px;
  right: -4px;
}
.app.mini .sub-menu .mat-button {
  padding-left: 0 !important;
}
.app.horizontal-menu.compact .logo {
  min-width: calc(260px - 16px);
}
.app.horizontal-menu.compact .logo span {
  display: block;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button {
  padding-top: 4px;
  padding-bottom: 4px;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button .horizontal-menu-title {
  line-height: 18px;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button .horizontal-menu-icon {
  margin: 0;
}
.app.horizontal-menu.compact .horizontal-menu-item .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button {
  flex-direction: row;
  justify-content: flex-start !important;
  align-items: center;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button .horizontal-menu-icon {
  margin-right: 10px;
}
.app.horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button-wrapper {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.app.horizontal-menu.mini .logo {
  min-width: calc(260px - 16px);
}
.app.horizontal-menu.mini .logo span {
  display: block;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button {
  min-width: 56px;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button .horizontal-menu-title {
  display: none;
}
.app.horizontal-menu.mini .horizontal-menu-item .mat-button .horizontal-menu-icon {
  margin: 0;
}
.app.horizontal-menu.mini .horizontal-menu-item .horizontal-sub-menu {
  width: 68px;
}
.app.horizontal-menu.mini .horizontal-menu-item .horizontal-sub-menu .mat-button {
  justify-content: center !important;
}

/* Material Components */
.mat-card {
  overflow: hidden;
}
.mat-card.custom-card .mat-card-header {
  margin: -16px -16px 0;
}
.mat-card.custom-card .mat-card-header .mat-card-title {
  font-size: 14px;
}
.mat-card.custom-card .mat-card-actions {
  padding-left: 6px;
  padding-right: 6px;
}

.mat-list .mat-list-item,
.mat-nav-list .mat-list-item,
.mat-selection-list .mat-list-item,
.mat-menu-item {
  font-size: 14px !important;
}

.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}

.vertical-tabs.mat-tab-group {
  flex-direction: row;
}
.vertical-tabs.mat-tab-group .mat-tab-labels {
  flex-direction: column;
}
.vertical-tabs.mat-tab-group .mat-ink-bar {
  display: none;
}
.vertical-tabs.mat-tab-group .mat-tab-header {
  border: none !important;
}
.vertical-tabs.mat-tab-group .mat-tab-body-wrapper {
  padding: 0 16px;
}

.mail-body p {
  margin-bottom: 1rem;
}
.mail-body ul {
  margin: 0 16px;
}
.mail-body .ql-container.ql-snow {
  min-height: 120px;
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}
::-webkit-scrollbar-thumb:active {
  background: #888888;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.5rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

/* perfect-scrollbar */
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 7px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 7px;
  right: 0px;
  position: absolute;
  z-index: 99;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  bottom: 1px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  right: 1px;
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 5px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.sebm-google-map-container {
  height: 500px;
  height: calc(100vh - 112px);
}

#leaflet-map {
  width: 100%;
  height: 490px;
  height: calc(100vh - 112px);
}

/* -- NGU carousel -- */
.tile {
  box-shadow: none !important;
}

.nguCarouselPoints {
  list-style-type: none;
  text-align: center;
  padding: 12px;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
  box-sizing: border-box;
}

.nguCarouselPoints li {
  display: inline-block;
  border-radius: 50%;
  background: #6b6b6b;
  padding: 4px;
  margin: 0 3px;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: 0.4s;
}

.nguCarouselPoints li.active {
  background: #6b6b6b;
  transform: scale(1.5);
}

.nguCarouselPoints li:hover {
  cursor: pointer;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.app.indigo-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.indigo-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.indigo-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-option:hover:not(.mat-option-disabled), .app.indigo-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #303f9f;
}
.app.indigo-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.indigo-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.indigo-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.indigo-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.indigo-light .mat-primary .mat-pseudo-checkbox-checked,
.app.indigo-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #303f9f;
}
.app.indigo-light .mat-pseudo-checkbox-checked,
.app.indigo-light .mat-pseudo-checkbox-indeterminate,
.app.indigo-light .mat-accent .mat-pseudo-checkbox-checked,
.app.indigo-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.indigo-light .mat-warn .mat-pseudo-checkbox-checked,
.app.indigo-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.indigo-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.indigo-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.indigo-light .mat-app-background, .app.indigo-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.indigo-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.indigo-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-badge {
  position: relative;
}
.app.indigo-light .mat-badge.mat-badge {
  overflow: visible;
}
.app.indigo-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.indigo-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.indigo-light .ng-animate-disabled .mat-badge-content,
.app.indigo-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.indigo-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.indigo-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.indigo-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.indigo-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.indigo-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.indigo-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.indigo-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.indigo-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.indigo-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.indigo-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.indigo-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.indigo-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.indigo-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.indigo-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.indigo-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.indigo-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.indigo-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.indigo-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.indigo-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.indigo-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.indigo-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.indigo-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.indigo-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.indigo-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.indigo-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.indigo-light .mat-badge-content {
  color: white;
  background: #303f9f;
}
.cdk-high-contrast-active .app.indigo-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.indigo-light .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.indigo-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.indigo-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-button, .app.indigo-light .mat-icon-button, .app.indigo-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.indigo-light .mat-button.mat-primary, .app.indigo-light .mat-icon-button.mat-primary, .app.indigo-light .mat-stroked-button.mat-primary {
  color: #303f9f;
}
.app.indigo-light .mat-button.mat-accent, .app.indigo-light .mat-icon-button.mat-accent, .app.indigo-light .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.indigo-light .mat-button.mat-warn, .app.indigo-light .mat-icon-button.mat-warn, .app.indigo-light .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.indigo-light .mat-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-icon-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-icon-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-icon-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-button.mat-primary .mat-button-focus-overlay, .app.indigo-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.indigo-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #303f9f;
}
.app.indigo-light .mat-button.mat-accent .mat-button-focus-overlay, .app.indigo-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.indigo-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.indigo-light .mat-button.mat-warn .mat-button-focus-overlay, .app.indigo-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.indigo-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.indigo-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.indigo-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.indigo-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.indigo-light .mat-button .mat-ripple-element, .app.indigo-light .mat-icon-button .mat-ripple-element, .app.indigo-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.indigo-light .mat-button-focus-overlay {
  background: black;
}
.app.indigo-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-flat-button, .app.indigo-light .mat-raised-button, .app.indigo-light .mat-fab, .app.indigo-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.indigo-light .mat-flat-button.mat-primary, .app.indigo-light .mat-raised-button.mat-primary, .app.indigo-light .mat-fab.mat-primary, .app.indigo-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.indigo-light .mat-flat-button.mat-accent, .app.indigo-light .mat-raised-button.mat-accent, .app.indigo-light .mat-fab.mat-accent, .app.indigo-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.indigo-light .mat-flat-button.mat-warn, .app.indigo-light .mat-raised-button.mat-warn, .app.indigo-light .mat-fab.mat-warn, .app.indigo-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.indigo-light .mat-flat-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-fab.mat-primary.mat-button-disabled, .app.indigo-light .mat-fab.mat-accent.mat-button-disabled, .app.indigo-light .mat-fab.mat-warn.mat-button-disabled, .app.indigo-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-flat-button.mat-primary, .app.indigo-light .mat-raised-button.mat-primary, .app.indigo-light .mat-fab.mat-primary, .app.indigo-light .mat-mini-fab.mat-primary {
  background-color: #303f9f;
}
.app.indigo-light .mat-flat-button.mat-accent, .app.indigo-light .mat-raised-button.mat-accent, .app.indigo-light .mat-fab.mat-accent, .app.indigo-light .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.indigo-light .mat-flat-button.mat-warn, .app.indigo-light .mat-raised-button.mat-warn, .app.indigo-light .mat-fab.mat-warn, .app.indigo-light .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.indigo-light .mat-flat-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-primary.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-accent.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-warn.mat-button-disabled, .app.indigo-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-fab.mat-primary.mat-button-disabled, .app.indigo-light .mat-fab.mat-accent.mat-button-disabled, .app.indigo-light .mat-fab.mat-warn.mat-button-disabled, .app.indigo-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.indigo-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-flat-button.mat-primary .mat-ripple-element, .app.indigo-light .mat-raised-button.mat-primary .mat-ripple-element, .app.indigo-light .mat-fab.mat-primary .mat-ripple-element, .app.indigo-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-flat-button.mat-accent .mat-ripple-element, .app.indigo-light .mat-raised-button.mat-accent .mat-ripple-element, .app.indigo-light .mat-fab.mat-accent .mat-ripple-element, .app.indigo-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-flat-button.mat-warn .mat-ripple-element, .app.indigo-light .mat-raised-button.mat-warn .mat-ripple-element, .app.indigo-light .mat-fab.mat-warn .mat-ripple-element, .app.indigo-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.indigo-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-fab:not([class*=mat-elevation-z]), .app.indigo-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.indigo-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.indigo-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.indigo-light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.indigo-light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.indigo-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.indigo-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.indigo-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.indigo-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.indigo-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.indigo-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.indigo-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.indigo-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.indigo-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.indigo-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.indigo-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.indigo-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.indigo-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.indigo-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.indigo-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #303f9f;
}
.app.indigo-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.indigo-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.indigo-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.indigo-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.indigo-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.indigo-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.indigo-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.indigo-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #303f9f;
}
.app.indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.indigo-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.indigo-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.indigo-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.indigo-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.indigo-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #303f9f;
  color: white;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.indigo-light .mat-table {
  background: white;
}
.app.indigo-light .mat-table thead, .app.indigo-light .mat-table tbody, .app.indigo-light .mat-table tfoot,
.app.indigo-light mat-header-row, .app.indigo-light mat-row, .app.indigo-light mat-footer-row,
.app.indigo-light [mat-header-row], .app.indigo-light [mat-row], .app.indigo-light [mat-footer-row],
.app.indigo-light .mat-table-sticky {
  background: inherit;
}
.app.indigo-light mat-row, .app.indigo-light mat-header-row, .app.indigo-light mat-footer-row,
.app.indigo-light th.mat-header-cell, .app.indigo-light td.mat-cell, .app.indigo-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-cell, .app.indigo-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-datepicker-toggle,
.app.indigo-light .mat-datepicker-content .mat-calendar-next-button,
.app.indigo-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-calendar-table-header,
.app.indigo-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-calendar-body-cell-content,
.app.indigo-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.indigo-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.indigo-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.indigo-light .mat-calendar-body-in-range::before {
  background: rgba(48, 63, 159, 0.2);
}
.app.indigo-light .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.indigo-light .mat-calendar-body-comparison-bridge-start::before,
.app.indigo-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(48, 63, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-calendar-body-comparison-bridge-end::before,
.app.indigo-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(48, 63, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.indigo-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.indigo-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.indigo-light .mat-calendar-body-selected {
  background-color: #303f9f;
  color: white;
}
.app.indigo-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.indigo-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.indigo-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.indigo-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(48, 63, 159, 0.3);
}
@media (hover: hover) {
  .app.indigo-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(48, 63, 159, 0.3);
  }
}
.app.indigo-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.indigo-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.indigo-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.indigo-light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.indigo-light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .app.indigo-light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.indigo-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.indigo-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.indigo-light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.indigo-light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.indigo-light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.indigo-light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-datepicker-toggle-active {
  color: #303f9f;
}
.app.indigo-light .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.indigo-light .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.indigo-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.indigo-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.indigo-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.indigo-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.indigo-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-expansion-panel-header-description,
.app.indigo-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.indigo-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.indigo-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.indigo-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #303f9f;
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.indigo-light .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.indigo-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #303f9f;
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.indigo-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #303f9f;
}
.app.indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.indigo-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.indigo-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.indigo-light .mat-error {
  color: #f44336;
}
.app.indigo-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.indigo-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.indigo-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.indigo-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.indigo-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #303f9f;
}
.app.indigo-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.indigo-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.indigo-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.indigo-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.indigo-light .mat-icon.mat-primary {
  color: #303f9f;
}
.app.indigo-light .mat-icon.mat-accent {
  color: #ff4081;
}
.app.indigo-light .mat-icon.mat-warn {
  color: #f44336;
}
.app.indigo-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-input-element:disabled,
.app.indigo-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-input-element {
  caret-color: #303f9f;
}
.app.indigo-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.indigo-light .mat-form-field.mat-warn .mat-input-element,
.app.indigo-light .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.indigo-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.indigo-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-list-option:hover, .app.indigo-light .mat-list-option:focus,
.app.indigo-light .mat-nav-list .mat-list-item:hover,
.app.indigo-light .mat-nav-list .mat-list-item:focus,
.app.indigo-light .mat-action-list .mat-list-item:hover,
.app.indigo-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-list-single-selected-option, .app.indigo-light .mat-list-single-selected-option:hover, .app.indigo-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-menu-panel {
  background: white;
}
.app.indigo-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-menu-item[disabled],
.app.indigo-light .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.indigo-light .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-menu-item .mat-icon-no-color,
.app.indigo-light .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-menu-item:hover:not([disabled]),
.app.indigo-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.indigo-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.indigo-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-paginator {
  background: white;
}
.app.indigo-light .mat-paginator,
.app.indigo-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-paginator-decrement,
.app.indigo-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-paginator-first,
.app.indigo-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.indigo-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.indigo-light .mat-icon-button[disabled] .mat-paginator-first,
.app.indigo-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-progress-bar-background {
  fill: #c8cbe3;
}
.app.indigo-light .mat-progress-bar-buffer {
  background-color: #c8cbe3;
}
.app.indigo-light .mat-progress-bar-fill::after {
  background-color: #303f9f;
}
.app.indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.app.indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.app.indigo-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.app.indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.app.indigo-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.indigo-light .mat-progress-spinner circle, .app.indigo-light .mat-spinner circle {
  stroke: #303f9f;
}
.app.indigo-light .mat-progress-spinner.mat-accent circle, .app.indigo-light .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.indigo-light .mat-progress-spinner.mat-warn circle, .app.indigo-light .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.indigo-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #303f9f;
}
.app.indigo-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.indigo-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.indigo-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.indigo-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #303f9f;
}
.app.indigo-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.indigo-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.indigo-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.indigo-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.indigo-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.indigo-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.indigo-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.indigo-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.indigo-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.indigo-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.indigo-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.indigo-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.indigo-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-select-panel {
  background: white;
}
.app.indigo-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #303f9f;
}
.app.indigo-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.indigo-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.indigo-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.indigo-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.indigo-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.indigo-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.indigo-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.indigo-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.indigo-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.indigo-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #303f9f;
}
.app.indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(48, 63, 159, 0.54);
}
.app.indigo-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #303f9f;
}
.app.indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.indigo-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.indigo-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.indigo-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.indigo-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-slider.mat-primary .mat-slider-track-fill,
.app.indigo-light .mat-slider.mat-primary .mat-slider-thumb,
.app.indigo-light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #303f9f;
}
.app.indigo-light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.indigo-light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(48, 63, 159, 0.2);
}
.app.indigo-light .mat-slider.mat-accent .mat-slider-track-fill,
.app.indigo-light .mat-slider.mat-accent .mat-slider-thumb,
.app.indigo-light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.indigo-light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.indigo-light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.indigo-light .mat-slider.mat-warn .mat-slider-track-fill,
.app.indigo-light .mat-slider.mat-warn .mat-slider-thumb,
.app.indigo-light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.indigo-light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.indigo-light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.indigo-light .mat-slider:hover .mat-slider-track-background,
.app.indigo-light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.indigo-light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.indigo-light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.indigo-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.indigo-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.indigo-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.indigo-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.indigo-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.indigo-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.indigo-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.indigo-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.indigo-light .mat-step-header.cdk-keyboard-focused, .app.indigo-light .mat-step-header.cdk-program-focused, .app.indigo-light .mat-step-header:hover:not([aria-disabled]), .app.indigo-light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.indigo-light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.indigo-light .mat-step-header:hover {
    background: none;
  }
}
.app.indigo-light .mat-step-header .mat-step-label,
.app.indigo-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.indigo-light .mat-step-header .mat-step-icon-selected,
.app.indigo-light .mat-step-header .mat-step-icon-state-done,
.app.indigo-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #303f9f;
  color: white;
}
.app.indigo-light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.indigo-light .mat-step-header.mat-accent .mat-step-icon-selected,
.app.indigo-light .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.indigo-light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.app.indigo-light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.indigo-light .mat-step-header.mat-warn .mat-step-icon-selected,
.app.indigo-light .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.indigo-light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.indigo-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.indigo-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.indigo-light .mat-stepper-horizontal, .app.indigo-light .mat-stepper-vertical {
  background-color: white;
}
.app.indigo-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-horizontal-stepper-header::before,
.app.indigo-light .mat-horizontal-stepper-header::after,
.app.indigo-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-sort-header-arrow {
  color: #757575;
}
.app.indigo-light .mat-tab-nav-bar,
.app.indigo-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.indigo-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.indigo-light .mat-tab-label, .app.indigo-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.indigo-light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.indigo-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.indigo-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 234, 246, 0.3);
}
.app.indigo-light .mat-tab-group.mat-primary .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #303f9f;
}
.app.indigo-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.indigo-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.indigo-light .mat-tab-group.mat-accent .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.indigo-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.indigo-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.indigo-light .mat-tab-group.mat-warn .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.indigo-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.indigo-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.indigo-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 234, 246, 0.3);
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header, .app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #303f9f;
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.indigo-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header, .app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.indigo-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.indigo-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header, .app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.indigo-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.indigo-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-toolbar.mat-primary {
  background: #303f9f;
  color: white;
}
.app.indigo-light .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.indigo-light .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.indigo-light .mat-toolbar .mat-form-field-underline,
.app.indigo-light .mat-toolbar .mat-form-field-ripple,
.app.indigo-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.indigo-light .mat-toolbar .mat-form-field-label,
.app.indigo-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.indigo-light .mat-toolbar .mat-select-value,
.app.indigo-light .mat-toolbar .mat-select-arrow,
.app.indigo-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.indigo-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.indigo-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.indigo-light .mat-tree {
  background: white;
}
.app.indigo-light .mat-tree-node,
.app.indigo-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.indigo-light .mat-simple-snackbar-action {
  color: #ff4081;
}
.app.indigo-light .bg-primary {
  background: #303f9f;
  color: white;
}
.app.indigo-light .bg-accent {
  background: #ff4081;
  color: white;
}
.app.indigo-light .bg-warn {
  background: #f44336;
  color: white;
}
.app.indigo-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .primary-color {
  color: #303f9f !important;
}
.app.indigo-light .accent-color {
  color: #ff4081 !important;
}
.app.indigo-light .warn-color {
  color: #f44336 !important;
}
.app.indigo-light .header {
  background: white;
  color: black;
}
.app.indigo-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .content-header h4 {
  color: #e8eaf6;
}
.app.indigo-light .favorites-container:after {
  color: #ff4081;
}
.app.indigo-light .horizontal-menu {
  background: white;
}
.app.indigo-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.indigo-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #303f9f;
  color: #fff;
}
.app.indigo-light .menu-item .mat-button .menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.indigo-light .menu-item .mat-button.active-link .menu-icon {
  background: #303f9f;
  color: #fff;
}
.app.indigo-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.indigo-light .breadcrumb a {
  color: #e8eaf6;
}
.app.indigo-light .top-toolbar, .app.indigo-light .landing-toolbar {
  background: #283593;
  color: #e8eaf6;
}
.app.indigo-light .menu-toolbar {
  background: #1a237e;
  color: #c5cae9;
}
.app.indigo-light .ps__thumb-y {
  background-color: #303f9f;
}
.app.indigo-light .testimonial-item, .app.indigo-light .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.indigo-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.indigo-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.indigo-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.indigo-light .annular-pagination .ngx-pagination .current {
  background: #303f9f;
  color: white;
}
.app.indigo-light .annular-pagination .ngx-pagination a:hover, .app.indigo-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(48, 63, 159, 0.2);
  color: black;
}
.app.indigo-light .cal-day-view .cal-hour-segment:hover,
.app.indigo-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.indigo-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.indigo-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.indigo-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.indigo-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.indigo-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(48, 63, 159, 0.05);
}
.app.indigo-light .cal-week-view .cal-header.cal-today,
.app.indigo-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(48, 63, 159, 0.4);
}
.app.indigo-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.indigo-light .cal-event-action i {
  margin: 4px;
}
.app.indigo-light .ql-snow .ql-picker,
.app.indigo-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.indigo-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.indigo-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.indigo-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.teal-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.teal-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.teal-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-option:hover:not(.mat-option-disabled), .app.teal-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00796b;
}
.app.teal-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f4511e;
}
.app.teal-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e53935;
}
.app.teal-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.teal-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.teal-light .mat-primary .mat-pseudo-checkbox-checked,
.app.teal-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00796b;
}
.app.teal-light .mat-pseudo-checkbox-checked,
.app.teal-light .mat-pseudo-checkbox-indeterminate,
.app.teal-light .mat-accent .mat-pseudo-checkbox-checked,
.app.teal-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #f4511e;
}
.app.teal-light .mat-warn .mat-pseudo-checkbox-checked,
.app.teal-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #e53935;
}
.app.teal-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.teal-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.teal-light .mat-app-background, .app.teal-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.teal-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.teal-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-badge {
  position: relative;
}
.app.teal-light .mat-badge.mat-badge {
  overflow: visible;
}
.app.teal-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.teal-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.teal-light .ng-animate-disabled .mat-badge-content,
.app.teal-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.teal-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.teal-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.teal-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.teal-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.teal-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.teal-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.teal-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.teal-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.teal-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.teal-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.teal-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.teal-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.teal-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.teal-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.teal-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.teal-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.teal-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.teal-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.teal-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.teal-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.teal-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.teal-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.teal-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.teal-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.teal-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.teal-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.teal-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.teal-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.teal-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.teal-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.teal-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.teal-light .mat-badge-content {
  color: white;
  background: #00796b;
}
.cdk-high-contrast-active .app.teal-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.teal-light .mat-badge-accent .mat-badge-content {
  background: #f4511e;
  color: white;
}
.app.teal-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #e53935;
}
.app.teal-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-button, .app.teal-light .mat-icon-button, .app.teal-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.teal-light .mat-button.mat-primary, .app.teal-light .mat-icon-button.mat-primary, .app.teal-light .mat-stroked-button.mat-primary {
  color: #00796b;
}
.app.teal-light .mat-button.mat-accent, .app.teal-light .mat-icon-button.mat-accent, .app.teal-light .mat-stroked-button.mat-accent {
  color: #f4511e;
}
.app.teal-light .mat-button.mat-warn, .app.teal-light .mat-icon-button.mat-warn, .app.teal-light .mat-stroked-button.mat-warn {
  color: #e53935;
}
.app.teal-light .mat-button.mat-primary.mat-button-disabled, .app.teal-light .mat-button.mat-accent.mat-button-disabled, .app.teal-light .mat-button.mat-warn.mat-button-disabled, .app.teal-light .mat-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-icon-button.mat-primary.mat-button-disabled, .app.teal-light .mat-icon-button.mat-accent.mat-button-disabled, .app.teal-light .mat-icon-button.mat-warn.mat-button-disabled, .app.teal-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.teal-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.teal-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.teal-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-button.mat-primary .mat-button-focus-overlay, .app.teal-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.teal-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00796b;
}
.app.teal-light .mat-button.mat-accent .mat-button-focus-overlay, .app.teal-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.teal-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #f4511e;
}
.app.teal-light .mat-button.mat-warn .mat-button-focus-overlay, .app.teal-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.teal-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #e53935;
}
.app.teal-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.teal-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.teal-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.teal-light .mat-button .mat-ripple-element, .app.teal-light .mat-icon-button .mat-ripple-element, .app.teal-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.teal-light .mat-button-focus-overlay {
  background: black;
}
.app.teal-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-flat-button, .app.teal-light .mat-raised-button, .app.teal-light .mat-fab, .app.teal-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.teal-light .mat-flat-button.mat-primary, .app.teal-light .mat-raised-button.mat-primary, .app.teal-light .mat-fab.mat-primary, .app.teal-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.teal-light .mat-flat-button.mat-accent, .app.teal-light .mat-raised-button.mat-accent, .app.teal-light .mat-fab.mat-accent, .app.teal-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.teal-light .mat-flat-button.mat-warn, .app.teal-light .mat-raised-button.mat-warn, .app.teal-light .mat-fab.mat-warn, .app.teal-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.teal-light .mat-flat-button.mat-primary.mat-button-disabled, .app.teal-light .mat-flat-button.mat-accent.mat-button-disabled, .app.teal-light .mat-flat-button.mat-warn.mat-button-disabled, .app.teal-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-raised-button.mat-primary.mat-button-disabled, .app.teal-light .mat-raised-button.mat-accent.mat-button-disabled, .app.teal-light .mat-raised-button.mat-warn.mat-button-disabled, .app.teal-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-fab.mat-primary.mat-button-disabled, .app.teal-light .mat-fab.mat-accent.mat-button-disabled, .app.teal-light .mat-fab.mat-warn.mat-button-disabled, .app.teal-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-flat-button.mat-primary, .app.teal-light .mat-raised-button.mat-primary, .app.teal-light .mat-fab.mat-primary, .app.teal-light .mat-mini-fab.mat-primary {
  background-color: #00796b;
}
.app.teal-light .mat-flat-button.mat-accent, .app.teal-light .mat-raised-button.mat-accent, .app.teal-light .mat-fab.mat-accent, .app.teal-light .mat-mini-fab.mat-accent {
  background-color: #f4511e;
}
.app.teal-light .mat-flat-button.mat-warn, .app.teal-light .mat-raised-button.mat-warn, .app.teal-light .mat-fab.mat-warn, .app.teal-light .mat-mini-fab.mat-warn {
  background-color: #e53935;
}
.app.teal-light .mat-flat-button.mat-primary.mat-button-disabled, .app.teal-light .mat-flat-button.mat-accent.mat-button-disabled, .app.teal-light .mat-flat-button.mat-warn.mat-button-disabled, .app.teal-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-raised-button.mat-primary.mat-button-disabled, .app.teal-light .mat-raised-button.mat-accent.mat-button-disabled, .app.teal-light .mat-raised-button.mat-warn.mat-button-disabled, .app.teal-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-fab.mat-primary.mat-button-disabled, .app.teal-light .mat-fab.mat-accent.mat-button-disabled, .app.teal-light .mat-fab.mat-warn.mat-button-disabled, .app.teal-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.teal-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-flat-button.mat-primary .mat-ripple-element, .app.teal-light .mat-raised-button.mat-primary .mat-ripple-element, .app.teal-light .mat-fab.mat-primary .mat-ripple-element, .app.teal-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-flat-button.mat-accent .mat-ripple-element, .app.teal-light .mat-raised-button.mat-accent .mat-ripple-element, .app.teal-light .mat-fab.mat-accent .mat-ripple-element, .app.teal-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-flat-button.mat-warn .mat-ripple-element, .app.teal-light .mat-raised-button.mat-warn .mat-ripple-element, .app.teal-light .mat-fab.mat-warn .mat-ripple-element, .app.teal-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.teal-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-fab:not([class*=mat-elevation-z]), .app.teal-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.teal-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.teal-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.teal-light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.teal-light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.teal-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.teal-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.teal-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.teal-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.teal-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.teal-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.teal-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.teal-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.teal-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.teal-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.teal-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.teal-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.teal-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.teal-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.teal-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00796b;
}
.app.teal-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.teal-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f4511e;
}
.app.teal-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.teal-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #e53935;
}
.app.teal-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.teal-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.teal-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.teal-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00796b;
}
.app.teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #f4511e;
}
.app.teal-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.teal-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #e53935;
}
.app.teal-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.teal-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.teal-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00796b;
  color: white;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #e53935;
  color: white;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #f4511e;
  color: white;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.teal-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.teal-light .mat-table {
  background: white;
}
.app.teal-light .mat-table thead, .app.teal-light .mat-table tbody, .app.teal-light .mat-table tfoot,
.app.teal-light mat-header-row, .app.teal-light mat-row, .app.teal-light mat-footer-row,
.app.teal-light [mat-header-row], .app.teal-light [mat-row], .app.teal-light [mat-footer-row],
.app.teal-light .mat-table-sticky {
  background: inherit;
}
.app.teal-light mat-row, .app.teal-light mat-header-row, .app.teal-light mat-footer-row,
.app.teal-light th.mat-header-cell, .app.teal-light td.mat-cell, .app.teal-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-cell, .app.teal-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-datepicker-toggle,
.app.teal-light .mat-datepicker-content .mat-calendar-next-button,
.app.teal-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-calendar-table-header,
.app.teal-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-calendar-body-cell-content,
.app.teal-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.teal-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.teal-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.teal-light .mat-calendar-body-in-range::before {
  background: rgba(0, 121, 107, 0.2);
}
.app.teal-light .mat-calendar-body-comparison-identical,
.app.teal-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.teal-light .mat-calendar-body-comparison-bridge-start::before,
.app.teal-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 121, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-calendar-body-comparison-bridge-end::before,
.app.teal-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 121, 107, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.teal-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.teal-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.teal-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.teal-light .mat-calendar-body-selected {
  background-color: #00796b;
  color: white;
}
.app.teal-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.teal-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.teal-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.teal-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 121, 107, 0.3);
}
@media (hover: hover) {
  .app.teal-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 121, 107, 0.3);
  }
}
.app.teal-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(244, 81, 30, 0.2);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.teal-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.teal-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 81, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #f4511e;
  color: white;
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 81, 30, 0.4);
}
.app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.teal-light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.teal-light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 81, 30, 0.3);
}
@media (hover: hover) {
  .app.teal-light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 81, 30, 0.3);
  }
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(229, 57, 53, 0.2);
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.teal-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.teal-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(229, 57, 53, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #e53935;
  color: white;
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(229, 57, 53, 0.4);
}
.app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.teal-light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.teal-light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(229, 57, 53, 0.3);
}
@media (hover: hover) {
  .app.teal-light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(229, 57, 53, 0.3);
  }
}
.app.teal-light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-datepicker-toggle-active {
  color: #00796b;
}
.app.teal-light .mat-datepicker-toggle-active.mat-accent {
  color: #f4511e;
}
.app.teal-light .mat-datepicker-toggle-active.mat-warn {
  color: #e53935;
}
.app.teal-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.teal-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.teal-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.teal-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.teal-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-expansion-panel-header-description,
.app.teal-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.teal-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.teal-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.teal-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #00796b;
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #f4511e;
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #e53935;
}
.app.teal-light .mat-focused .mat-form-field-required-marker {
  color: #f4511e;
}
.app.teal-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00796b;
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #f4511e;
}
.app.teal-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #e53935;
}
.app.teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00796b;
}
.app.teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #f4511e;
}
.app.teal-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #e53935;
}
.app.teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #e53935;
}
.app.teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #e53935;
}
.app.teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.teal-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e53935;
}
.app.teal-light .mat-error {
  color: #e53935;
}
.app.teal-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.teal-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.teal-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.teal-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.teal-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00796b;
}
.app.teal-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #f4511e;
}
.app.teal-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #e53935;
}
.app.teal-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #e53935;
}
.app.teal-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.teal-light .mat-icon.mat-primary {
  color: #00796b;
}
.app.teal-light .mat-icon.mat-accent {
  color: #f4511e;
}
.app.teal-light .mat-icon.mat-warn {
  color: #e53935;
}
.app.teal-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-input-element:disabled,
.app.teal-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-input-element {
  caret-color: #00796b;
}
.app.teal-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #f4511e;
}
.app.teal-light .mat-form-field.mat-warn .mat-input-element,
.app.teal-light .mat-form-field-invalid .mat-input-element {
  caret-color: #e53935;
}
.app.teal-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #e53935;
}
.app.teal-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-list-option:hover, .app.teal-light .mat-list-option:focus,
.app.teal-light .mat-nav-list .mat-list-item:hover,
.app.teal-light .mat-nav-list .mat-list-item:focus,
.app.teal-light .mat-action-list .mat-list-item:hover,
.app.teal-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-list-single-selected-option, .app.teal-light .mat-list-single-selected-option:hover, .app.teal-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-menu-panel {
  background: white;
}
.app.teal-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-menu-item[disabled],
.app.teal-light .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.teal-light .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-menu-item .mat-icon-no-color,
.app.teal-light .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-menu-item:hover:not([disabled]),
.app.teal-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.teal-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.teal-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-paginator {
  background: white;
}
.app.teal-light .mat-paginator,
.app.teal-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-paginator-decrement,
.app.teal-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-paginator-first,
.app.teal-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.teal-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.teal-light .mat-icon-button[disabled] .mat-paginator-first,
.app.teal-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-progress-bar-background {
  fill: #bcdad6;
}
.app.teal-light .mat-progress-bar-buffer {
  background-color: #bcdad6;
}
.app.teal-light .mat-progress-bar-fill::after {
  background-color: #00796b;
}
.app.teal-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f9d0c3;
}
.app.teal-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f9d0c3;
}
.app.teal-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #f4511e;
}
.app.teal-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f5cac9;
}
.app.teal-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f5cac9;
}
.app.teal-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #e53935;
}
.app.teal-light .mat-progress-spinner circle, .app.teal-light .mat-spinner circle {
  stroke: #00796b;
}
.app.teal-light .mat-progress-spinner.mat-accent circle, .app.teal-light .mat-spinner.mat-accent circle {
  stroke: #f4511e;
}
.app.teal-light .mat-progress-spinner.mat-warn circle, .app.teal-light .mat-spinner.mat-warn circle {
  stroke: #e53935;
}
.app.teal-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00796b;
}
.app.teal-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.teal-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.teal-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.teal-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00796b;
}
.app.teal-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f4511e;
}
.app.teal-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.teal-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.teal-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.teal-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f4511e;
}
.app.teal-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e53935;
}
.app.teal-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.teal-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.teal-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.teal-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #e53935;
}
.app.teal-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.teal-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.teal-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.teal-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.teal-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-select-panel {
  background: white;
}
.app.teal-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00796b;
}
.app.teal-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #f4511e;
}
.app.teal-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #e53935;
}
.app.teal-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #e53935;
}
.app.teal-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.teal-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.teal-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.teal-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.teal-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f4511e;
}
.app.teal-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 81, 30, 0.54);
}
.app.teal-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #f4511e;
}
.app.teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00796b;
}
.app.teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 121, 107, 0.54);
}
.app.teal-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00796b;
}
.app.teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #e53935;
}
.app.teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(229, 57, 53, 0.54);
}
.app.teal-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #e53935;
}
.app.teal-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.teal-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.teal-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-slider.mat-primary .mat-slider-track-fill,
.app.teal-light .mat-slider.mat-primary .mat-slider-thumb,
.app.teal-light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00796b;
}
.app.teal-light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.teal-light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 121, 107, 0.2);
}
.app.teal-light .mat-slider.mat-accent .mat-slider-track-fill,
.app.teal-light .mat-slider.mat-accent .mat-slider-thumb,
.app.teal-light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #f4511e;
}
.app.teal-light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.teal-light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(244, 81, 30, 0.2);
}
.app.teal-light .mat-slider.mat-warn .mat-slider-track-fill,
.app.teal-light .mat-slider.mat-warn .mat-slider-thumb,
.app.teal-light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #e53935;
}
.app.teal-light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.teal-light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(229, 57, 53, 0.2);
}
.app.teal-light .mat-slider:hover .mat-slider-track-background,
.app.teal-light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.teal-light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.teal-light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.teal-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.teal-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.teal-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.teal-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.teal-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.teal-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.teal-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.teal-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.teal-light .mat-step-header.cdk-keyboard-focused, .app.teal-light .mat-step-header.cdk-program-focused, .app.teal-light .mat-step-header:hover:not([aria-disabled]), .app.teal-light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.teal-light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.teal-light .mat-step-header:hover {
    background: none;
  }
}
.app.teal-light .mat-step-header .mat-step-label,
.app.teal-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.teal-light .mat-step-header .mat-step-icon-selected,
.app.teal-light .mat-step-header .mat-step-icon-state-done,
.app.teal-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #00796b;
  color: white;
}
.app.teal-light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.teal-light .mat-step-header.mat-accent .mat-step-icon-selected,
.app.teal-light .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.teal-light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #f4511e;
  color: white;
}
.app.teal-light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.teal-light .mat-step-header.mat-warn .mat-step-icon-selected,
.app.teal-light .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.teal-light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #e53935;
  color: white;
}
.app.teal-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #e53935;
}
.app.teal-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #e53935;
}
.app.teal-light .mat-stepper-horizontal, .app.teal-light .mat-stepper-vertical {
  background-color: white;
}
.app.teal-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-horizontal-stepper-header::before,
.app.teal-light .mat-horizontal-stepper-header::after,
.app.teal-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-sort-header-arrow {
  color: #757575;
}
.app.teal-light .mat-tab-nav-bar,
.app.teal-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.teal-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.teal-light .mat-tab-label, .app.teal-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.teal-light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.teal-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.teal-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 242, 241, 0.3);
}
.app.teal-light .mat-tab-group.mat-primary .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00796b;
}
.app.teal-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.teal-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 61, 0, 0.3);
}
.app.teal-light .mat-tab-group.mat-accent .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #f4511e;
}
.app.teal-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.teal-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.teal-light .mat-tab-group.mat-warn .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #e53935;
}
.app.teal-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.teal-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.teal-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(224, 242, 241, 0.3);
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header, .app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00796b;
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.teal-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 61, 0, 0.3);
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header, .app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #f4511e;
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.teal-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.teal-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header, .app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #e53935;
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.teal-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.teal-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-toolbar.mat-primary {
  background: #00796b;
  color: white;
}
.app.teal-light .mat-toolbar.mat-accent {
  background: #f4511e;
  color: white;
}
.app.teal-light .mat-toolbar.mat-warn {
  background: #e53935;
  color: white;
}
.app.teal-light .mat-toolbar .mat-form-field-underline,
.app.teal-light .mat-toolbar .mat-form-field-ripple,
.app.teal-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.teal-light .mat-toolbar .mat-form-field-label,
.app.teal-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.teal-light .mat-toolbar .mat-select-value,
.app.teal-light .mat-toolbar .mat-select-arrow,
.app.teal-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.teal-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.teal-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.teal-light .mat-tree {
  background: white;
}
.app.teal-light .mat-tree-node,
.app.teal-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.teal-light .mat-simple-snackbar-action {
  color: #f4511e;
}
.app.teal-light .bg-primary {
  background: #00796b;
  color: white;
}
.app.teal-light .bg-accent {
  background: #f4511e;
  color: white;
}
.app.teal-light .bg-warn {
  background: #e53935;
  color: white;
}
.app.teal-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .primary-color {
  color: #00796b !important;
}
.app.teal-light .accent-color {
  color: #f4511e !important;
}
.app.teal-light .warn-color {
  color: #e53935 !important;
}
.app.teal-light .header {
  background: white;
  color: black;
}
.app.teal-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.teal-light .content-header h4 {
  color: #e0f2f1;
}
.app.teal-light .favorites-container:after {
  color: #f4511e;
}
.app.teal-light .horizontal-menu {
  background: white;
}
.app.teal-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.teal-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.teal-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #00796b;
  color: #fff;
}
.app.teal-light .menu-item .mat-button .menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.teal-light .menu-item .mat-button.active-link .menu-icon {
  background: #00796b;
  color: #fff;
}
.app.teal-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.teal-light .breadcrumb a {
  color: #e0f2f1;
}
.app.teal-light .top-toolbar, .app.teal-light .landing-toolbar {
  background: #00695c;
  color: #e0f2f1;
}
.app.teal-light .menu-toolbar {
  background: #004d40;
  color: #b2dfdb;
}
.app.teal-light .ps__thumb-y {
  background-color: #00796b;
}
.app.teal-light .testimonial-item, .app.teal-light .pricing-card {
  border-top: 4px solid #f4511e;
}
.app.teal-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.teal-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.teal-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.teal-light .annular-pagination .ngx-pagination .current {
  background: #00796b;
  color: white;
}
.app.teal-light .annular-pagination .ngx-pagination a:hover, .app.teal-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(0, 121, 107, 0.2);
  color: black;
}
.app.teal-light .cal-day-view .cal-hour-segment:hover,
.app.teal-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.teal-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.teal-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.teal-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.teal-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.teal-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(0, 121, 107, 0.05);
}
.app.teal-light .cal-week-view .cal-header.cal-today,
.app.teal-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(0, 121, 107, 0.4);
}
.app.teal-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.teal-light .cal-event-action i {
  margin: 4px;
}
.app.teal-light .ql-snow .ql-picker,
.app.teal-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.teal-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.teal-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.teal-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.teal-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.teal-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.red-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.red-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.red-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-option:hover:not(.mat-option-disabled), .app.red-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d32f2f;
}
.app.red-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #43a047;
}
.app.red-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff3d00;
}
.app.red-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.red-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.red-light .mat-primary .mat-pseudo-checkbox-checked,
.app.red-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d32f2f;
}
.app.red-light .mat-pseudo-checkbox-checked,
.app.red-light .mat-pseudo-checkbox-indeterminate,
.app.red-light .mat-accent .mat-pseudo-checkbox-checked,
.app.red-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #43a047;
}
.app.red-light .mat-warn .mat-pseudo-checkbox-checked,
.app.red-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff3d00;
}
.app.red-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.red-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.red-light .mat-app-background, .app.red-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.red-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.red-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-badge {
  position: relative;
}
.app.red-light .mat-badge.mat-badge {
  overflow: visible;
}
.app.red-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.red-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.red-light .ng-animate-disabled .mat-badge-content,
.app.red-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.red-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.red-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.red-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.red-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.red-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.red-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.red-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.red-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.red-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.red-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.red-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.red-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.red-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.red-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.red-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.red-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.red-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.red-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.red-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.red-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.red-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.red-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.red-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.red-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.red-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.red-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.red-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.red-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.red-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.red-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.red-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.red-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.red-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.red-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.red-light .mat-badge-content {
  color: white;
  background: #d32f2f;
}
.cdk-high-contrast-active .app.red-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.red-light .mat-badge-accent .mat-badge-content {
  background: #43a047;
  color: white;
}
.app.red-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff3d00;
}
.app.red-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-button, .app.red-light .mat-icon-button, .app.red-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.red-light .mat-button.mat-primary, .app.red-light .mat-icon-button.mat-primary, .app.red-light .mat-stroked-button.mat-primary {
  color: #d32f2f;
}
.app.red-light .mat-button.mat-accent, .app.red-light .mat-icon-button.mat-accent, .app.red-light .mat-stroked-button.mat-accent {
  color: #43a047;
}
.app.red-light .mat-button.mat-warn, .app.red-light .mat-icon-button.mat-warn, .app.red-light .mat-stroked-button.mat-warn {
  color: #ff3d00;
}
.app.red-light .mat-button.mat-primary.mat-button-disabled, .app.red-light .mat-button.mat-accent.mat-button-disabled, .app.red-light .mat-button.mat-warn.mat-button-disabled, .app.red-light .mat-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-icon-button.mat-primary.mat-button-disabled, .app.red-light .mat-icon-button.mat-accent.mat-button-disabled, .app.red-light .mat-icon-button.mat-warn.mat-button-disabled, .app.red-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.red-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.red-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.red-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-button.mat-primary .mat-button-focus-overlay, .app.red-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.red-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d32f2f;
}
.app.red-light .mat-button.mat-accent .mat-button-focus-overlay, .app.red-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.red-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #43a047;
}
.app.red-light .mat-button.mat-warn .mat-button-focus-overlay, .app.red-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.red-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff3d00;
}
.app.red-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.red-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.red-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.red-light .mat-button .mat-ripple-element, .app.red-light .mat-icon-button .mat-ripple-element, .app.red-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.red-light .mat-button-focus-overlay {
  background: black;
}
.app.red-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-flat-button, .app.red-light .mat-raised-button, .app.red-light .mat-fab, .app.red-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.red-light .mat-flat-button.mat-primary, .app.red-light .mat-raised-button.mat-primary, .app.red-light .mat-fab.mat-primary, .app.red-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.red-light .mat-flat-button.mat-accent, .app.red-light .mat-raised-button.mat-accent, .app.red-light .mat-fab.mat-accent, .app.red-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.red-light .mat-flat-button.mat-warn, .app.red-light .mat-raised-button.mat-warn, .app.red-light .mat-fab.mat-warn, .app.red-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.red-light .mat-flat-button.mat-primary.mat-button-disabled, .app.red-light .mat-flat-button.mat-accent.mat-button-disabled, .app.red-light .mat-flat-button.mat-warn.mat-button-disabled, .app.red-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-raised-button.mat-primary.mat-button-disabled, .app.red-light .mat-raised-button.mat-accent.mat-button-disabled, .app.red-light .mat-raised-button.mat-warn.mat-button-disabled, .app.red-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-fab.mat-primary.mat-button-disabled, .app.red-light .mat-fab.mat-accent.mat-button-disabled, .app.red-light .mat-fab.mat-warn.mat-button-disabled, .app.red-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.red-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.red-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.red-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.red-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-flat-button.mat-primary, .app.red-light .mat-raised-button.mat-primary, .app.red-light .mat-fab.mat-primary, .app.red-light .mat-mini-fab.mat-primary {
  background-color: #d32f2f;
}
.app.red-light .mat-flat-button.mat-accent, .app.red-light .mat-raised-button.mat-accent, .app.red-light .mat-fab.mat-accent, .app.red-light .mat-mini-fab.mat-accent {
  background-color: #43a047;
}
.app.red-light .mat-flat-button.mat-warn, .app.red-light .mat-raised-button.mat-warn, .app.red-light .mat-fab.mat-warn, .app.red-light .mat-mini-fab.mat-warn {
  background-color: #ff3d00;
}
.app.red-light .mat-flat-button.mat-primary.mat-button-disabled, .app.red-light .mat-flat-button.mat-accent.mat-button-disabled, .app.red-light .mat-flat-button.mat-warn.mat-button-disabled, .app.red-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-raised-button.mat-primary.mat-button-disabled, .app.red-light .mat-raised-button.mat-accent.mat-button-disabled, .app.red-light .mat-raised-button.mat-warn.mat-button-disabled, .app.red-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.red-light .mat-fab.mat-primary.mat-button-disabled, .app.red-light .mat-fab.mat-accent.mat-button-disabled, .app.red-light .mat-fab.mat-warn.mat-button-disabled, .app.red-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.red-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.red-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.red-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.red-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-flat-button.mat-primary .mat-ripple-element, .app.red-light .mat-raised-button.mat-primary .mat-ripple-element, .app.red-light .mat-fab.mat-primary .mat-ripple-element, .app.red-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-flat-button.mat-accent .mat-ripple-element, .app.red-light .mat-raised-button.mat-accent .mat-ripple-element, .app.red-light .mat-fab.mat-accent .mat-ripple-element, .app.red-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-flat-button.mat-warn .mat-ripple-element, .app.red-light .mat-raised-button.mat-warn .mat-ripple-element, .app.red-light .mat-fab.mat-warn .mat-ripple-element, .app.red-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.red-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-fab:not([class*=mat-elevation-z]), .app.red-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.red-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.red-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.red-light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.red-light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.red-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.red-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.red-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.red-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.red-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.red-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.red-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.red-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.red-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.red-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.red-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.red-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.red-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.red-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.red-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d32f2f;
}
.app.red-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.red-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #43a047;
}
.app.red-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.red-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff3d00;
}
.app.red-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.red-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.red-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.red-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d32f2f;
}
.app.red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #43a047;
}
.app.red-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.red-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff3d00;
}
.app.red-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.red-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.red-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d32f2f;
  color: white;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff3d00;
  color: white;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #43a047;
  color: white;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.red-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.red-light .mat-table {
  background: white;
}
.app.red-light .mat-table thead, .app.red-light .mat-table tbody, .app.red-light .mat-table tfoot,
.app.red-light mat-header-row, .app.red-light mat-row, .app.red-light mat-footer-row,
.app.red-light [mat-header-row], .app.red-light [mat-row], .app.red-light [mat-footer-row],
.app.red-light .mat-table-sticky {
  background: inherit;
}
.app.red-light mat-row, .app.red-light mat-header-row, .app.red-light mat-footer-row,
.app.red-light th.mat-header-cell, .app.red-light td.mat-cell, .app.red-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-cell, .app.red-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-datepicker-toggle,
.app.red-light .mat-datepicker-content .mat-calendar-next-button,
.app.red-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-calendar-table-header,
.app.red-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-calendar-body-cell-content,
.app.red-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.red-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.red-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.red-light .mat-calendar-body-in-range::before {
  background: rgba(211, 47, 47, 0.2);
}
.app.red-light .mat-calendar-body-comparison-identical,
.app.red-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red-light .mat-calendar-body-comparison-bridge-start::before,
.app.red-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-calendar-body-comparison-bridge-end::before,
.app.red-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(211, 47, 47, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red-light .mat-calendar-body-selected {
  background-color: #d32f2f;
  color: white;
}
.app.red-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.red-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(211, 47, 47, 0.3);
}
@media (hover: hover) {
  .app.red-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(211, 47, 47, 0.3);
  }
}
.app.red-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(67, 160, 71, 0.2);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.red-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.red-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(67, 160, 71, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #43a047;
  color: white;
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(67, 160, 71, 0.4);
}
.app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red-light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red-light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(67, 160, 71, 0.3);
}
@media (hover: hover) {
  .app.red-light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(67, 160, 71, 0.3);
  }
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 61, 0, 0.2);
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.red-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.red-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 61, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff3d00;
  color: white;
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 61, 0, 0.4);
}
.app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.red-light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.red-light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 61, 0, 0.3);
}
@media (hover: hover) {
  .app.red-light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 61, 0, 0.3);
  }
}
.app.red-light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-datepicker-toggle-active {
  color: #d32f2f;
}
.app.red-light .mat-datepicker-toggle-active.mat-accent {
  color: #43a047;
}
.app.red-light .mat-datepicker-toggle-active.mat-warn {
  color: #ff3d00;
}
.app.red-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.red-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.red-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.red-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.red-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-expansion-panel-header-description,
.app.red-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.red-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.red-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.red-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #d32f2f;
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #43a047;
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff3d00;
}
.app.red-light .mat-focused .mat-form-field-required-marker {
  color: #43a047;
}
.app.red-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d32f2f;
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #43a047;
}
.app.red-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff3d00;
}
.app.red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d32f2f;
}
.app.red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #43a047;
}
.app.red-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff3d00;
}
.app.red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.red-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff3d00;
}
.app.red-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.red-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff3d00;
}
.app.red-light .mat-error {
  color: #ff3d00;
}
.app.red-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.red-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.red-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.red-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.red-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d32f2f;
}
.app.red-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #43a047;
}
.app.red-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.red-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff3d00;
}
.app.red-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.red-light .mat-icon.mat-primary {
  color: #d32f2f;
}
.app.red-light .mat-icon.mat-accent {
  color: #43a047;
}
.app.red-light .mat-icon.mat-warn {
  color: #ff3d00;
}
.app.red-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-input-element:disabled,
.app.red-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-input-element {
  caret-color: #d32f2f;
}
.app.red-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #43a047;
}
.app.red-light .mat-form-field.mat-warn .mat-input-element,
.app.red-light .mat-form-field-invalid .mat-input-element {
  caret-color: #ff3d00;
}
.app.red-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff3d00;
}
.app.red-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-list-option:hover, .app.red-light .mat-list-option:focus,
.app.red-light .mat-nav-list .mat-list-item:hover,
.app.red-light .mat-nav-list .mat-list-item:focus,
.app.red-light .mat-action-list .mat-list-item:hover,
.app.red-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-list-single-selected-option, .app.red-light .mat-list-single-selected-option:hover, .app.red-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-menu-panel {
  background: white;
}
.app.red-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-menu-item[disabled],
.app.red-light .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.red-light .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-menu-item .mat-icon-no-color,
.app.red-light .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-menu-item:hover:not([disabled]),
.app.red-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.red-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.red-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-paginator {
  background: white;
}
.app.red-light .mat-paginator,
.app.red-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-paginator-decrement,
.app.red-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-paginator-first,
.app.red-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.red-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.red-light .mat-icon-button[disabled] .mat-paginator-first,
.app.red-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-progress-bar-background {
  fill: #f0c7c7;
}
.app.red-light .mat-progress-bar-buffer {
  background-color: #f0c7c7;
}
.app.red-light .mat-progress-bar-fill::after {
  background-color: #d32f2f;
}
.app.red-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cce4cd;
}
.app.red-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cce4cd;
}
.app.red-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #43a047;
}
.app.red-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbcbbc;
}
.app.red-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbcbbc;
}
.app.red-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff3d00;
}
.app.red-light .mat-progress-spinner circle, .app.red-light .mat-spinner circle {
  stroke: #d32f2f;
}
.app.red-light .mat-progress-spinner.mat-accent circle, .app.red-light .mat-spinner.mat-accent circle {
  stroke: #43a047;
}
.app.red-light .mat-progress-spinner.mat-warn circle, .app.red-light .mat-spinner.mat-warn circle {
  stroke: #ff3d00;
}
.app.red-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d32f2f;
}
.app.red-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.red-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.red-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d32f2f;
}
.app.red-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #43a047;
}
.app.red-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.red-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.red-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #43a047;
}
.app.red-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff3d00;
}
.app.red-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.red-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.red-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.red-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff3d00;
}
.app.red-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.red-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.red-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.red-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.red-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-select-panel {
  background: white;
}
.app.red-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d32f2f;
}
.app.red-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #43a047;
}
.app.red-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff3d00;
}
.app.red-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff3d00;
}
.app.red-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.red-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.red-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.red-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #43a047;
}
.app.red-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(67, 160, 71, 0.54);
}
.app.red-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #43a047;
}
.app.red-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d32f2f;
}
.app.red-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(211, 47, 47, 0.54);
}
.app.red-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d32f2f;
}
.app.red-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff3d00;
}
.app.red-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 61, 0, 0.54);
}
.app.red-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff3d00;
}
.app.red-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.red-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.red-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-slider.mat-primary .mat-slider-track-fill,
.app.red-light .mat-slider.mat-primary .mat-slider-thumb,
.app.red-light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d32f2f;
}
.app.red-light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.red-light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(211, 47, 47, 0.2);
}
.app.red-light .mat-slider.mat-accent .mat-slider-track-fill,
.app.red-light .mat-slider.mat-accent .mat-slider-thumb,
.app.red-light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #43a047;
}
.app.red-light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.red-light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(67, 160, 71, 0.2);
}
.app.red-light .mat-slider.mat-warn .mat-slider-track-fill,
.app.red-light .mat-slider.mat-warn .mat-slider-thumb,
.app.red-light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff3d00;
}
.app.red-light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.red-light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 61, 0, 0.2);
}
.app.red-light .mat-slider:hover .mat-slider-track-background,
.app.red-light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.red-light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.red-light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.red-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.red-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.red-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.red-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.red-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.red-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.red-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.red-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.red-light .mat-step-header.cdk-keyboard-focused, .app.red-light .mat-step-header.cdk-program-focused, .app.red-light .mat-step-header:hover:not([aria-disabled]), .app.red-light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.red-light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.red-light .mat-step-header:hover {
    background: none;
  }
}
.app.red-light .mat-step-header .mat-step-label,
.app.red-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.red-light .mat-step-header .mat-step-icon-selected,
.app.red-light .mat-step-header .mat-step-icon-state-done,
.app.red-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #d32f2f;
  color: white;
}
.app.red-light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.red-light .mat-step-header.mat-accent .mat-step-icon-selected,
.app.red-light .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.red-light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #43a047;
  color: white;
}
.app.red-light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.red-light .mat-step-header.mat-warn .mat-step-icon-selected,
.app.red-light .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.red-light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff3d00;
  color: white;
}
.app.red-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff3d00;
}
.app.red-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff3d00;
}
.app.red-light .mat-stepper-horizontal, .app.red-light .mat-stepper-vertical {
  background-color: white;
}
.app.red-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-horizontal-stepper-header::before,
.app.red-light .mat-horizontal-stepper-header::after,
.app.red-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-sort-header-arrow {
  color: #757575;
}
.app.red-light .mat-tab-nav-bar,
.app.red-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.red-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.red-light .mat-tab-label, .app.red-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.red-light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.red-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.red-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.red-light .mat-tab-group.mat-primary .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d32f2f;
}
.app.red-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(129, 199, 132, 0.3);
}
.app.red-light .mat-tab-group.mat-accent .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #43a047;
}
.app.red-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.red-light .mat-tab-group.mat-warn .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff3d00;
}
.app.red-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.red-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.red-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 235, 238, 0.3);
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header, .app.red-light .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d32f2f;
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(129, 199, 132, 0.3);
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header, .app.red-light .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #43a047;
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.red-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header, .app.red-light .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff3d00;
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.red-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.red-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-toolbar.mat-primary {
  background: #d32f2f;
  color: white;
}
.app.red-light .mat-toolbar.mat-accent {
  background: #43a047;
  color: white;
}
.app.red-light .mat-toolbar.mat-warn {
  background: #ff3d00;
  color: white;
}
.app.red-light .mat-toolbar .mat-form-field-underline,
.app.red-light .mat-toolbar .mat-form-field-ripple,
.app.red-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.red-light .mat-toolbar .mat-form-field-label,
.app.red-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.red-light .mat-toolbar .mat-select-value,
.app.red-light .mat-toolbar .mat-select-arrow,
.app.red-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.red-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.red-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.red-light .mat-tree {
  background: white;
}
.app.red-light .mat-tree-node,
.app.red-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.red-light .mat-simple-snackbar-action {
  color: #43a047;
}
.app.red-light .bg-primary {
  background: #d32f2f;
  color: white;
}
.app.red-light .bg-accent {
  background: #43a047;
  color: white;
}
.app.red-light .bg-warn {
  background: #ff3d00;
  color: white;
}
.app.red-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .primary-color {
  color: #d32f2f !important;
}
.app.red-light .accent-color {
  color: #43a047 !important;
}
.app.red-light .warn-color {
  color: #ff3d00 !important;
}
.app.red-light .header {
  background: white;
  color: black;
}
.app.red-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.red-light .content-header h4 {
  color: #ffebee;
}
.app.red-light .favorites-container:after {
  color: #43a047;
}
.app.red-light .horizontal-menu {
  background: white;
}
.app.red-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.red-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.red-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #d32f2f;
  color: #fff;
}
.app.red-light .menu-item .mat-button .menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.red-light .menu-item .mat-button.active-link .menu-icon {
  background: #d32f2f;
  color: #fff;
}
.app.red-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.red-light .breadcrumb a {
  color: #ffebee;
}
.app.red-light .top-toolbar, .app.red-light .landing-toolbar {
  background: #c62828;
  color: #ffebee;
}
.app.red-light .menu-toolbar {
  background: #b71c1c;
  color: #ffcdd2;
}
.app.red-light .ps__thumb-y {
  background-color: #d32f2f;
}
.app.red-light .testimonial-item, .app.red-light .pricing-card {
  border-top: 4px solid #43a047;
}
.app.red-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.red-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.red-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.red-light .annular-pagination .ngx-pagination .current {
  background: #d32f2f;
  color: white;
}
.app.red-light .annular-pagination .ngx-pagination a:hover, .app.red-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(211, 47, 47, 0.2);
  color: black;
}
.app.red-light .cal-day-view .cal-hour-segment:hover,
.app.red-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.red-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.red-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.red-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.red-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.red-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(211, 47, 47, 0.05);
}
.app.red-light .cal-week-view .cal-header.cal-today,
.app.red-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(211, 47, 47, 0.4);
}
.app.red-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.red-light .cal-event-action i {
  margin: 4px;
}
.app.red-light .ql-snow .ql-picker,
.app.red-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.red-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.red-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.red-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.red-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.red-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.gray-light {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.gray-light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.gray-light .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-option:hover:not(.mat-option-disabled), .app.gray-light .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #616161;
}
.app.gray-light .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.gray-light .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.gray-light .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.app.gray-light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.app.gray-light .mat-primary .mat-pseudo-checkbox-checked,
.app.gray-light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #616161;
}
.app.gray-light .mat-pseudo-checkbox-checked,
.app.gray-light .mat-pseudo-checkbox-indeterminate,
.app.gray-light .mat-accent .mat-pseudo-checkbox-checked,
.app.gray-light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.gray-light .mat-warn .mat-pseudo-checkbox-checked,
.app.gray-light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.gray-light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.gray-light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.app.gray-light .mat-app-background, .app.gray-light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.gray-light .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.app.gray-light .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-badge {
  position: relative;
}
.app.gray-light .mat-badge.mat-badge {
  overflow: visible;
}
.app.gray-light .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.gray-light .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.gray-light .ng-animate-disabled .mat-badge-content,
.app.gray-light .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.gray-light .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.gray-light .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.gray-light .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.gray-light .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.gray-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.gray-light .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.gray-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.gray-light .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.gray-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.gray-light .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.gray-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.gray-light .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.gray-light .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.gray-light .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.gray-light .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.gray-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.gray-light .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.gray-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.gray-light .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.gray-light .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.gray-light .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.gray-light .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.gray-light .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.gray-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.gray-light .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.gray-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.gray-light .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.gray-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.gray-light .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.gray-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.gray-light .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.gray-light .mat-badge-content {
  color: white;
  background: #616161;
}
.cdk-high-contrast-active .app.gray-light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.gray-light .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.gray-light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.gray-light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-button, .app.gray-light .mat-icon-button, .app.gray-light .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.gray-light .mat-button.mat-primary, .app.gray-light .mat-icon-button.mat-primary, .app.gray-light .mat-stroked-button.mat-primary {
  color: #616161;
}
.app.gray-light .mat-button.mat-accent, .app.gray-light .mat-icon-button.mat-accent, .app.gray-light .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.gray-light .mat-button.mat-warn, .app.gray-light .mat-icon-button.mat-warn, .app.gray-light .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.gray-light .mat-button.mat-primary.mat-button-disabled, .app.gray-light .mat-button.mat-accent.mat-button-disabled, .app.gray-light .mat-button.mat-warn.mat-button-disabled, .app.gray-light .mat-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-icon-button.mat-primary.mat-button-disabled, .app.gray-light .mat-icon-button.mat-accent.mat-button-disabled, .app.gray-light .mat-icon-button.mat-warn.mat-button-disabled, .app.gray-light .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-stroked-button.mat-primary.mat-button-disabled, .app.gray-light .mat-stroked-button.mat-accent.mat-button-disabled, .app.gray-light .mat-stroked-button.mat-warn.mat-button-disabled, .app.gray-light .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-button.mat-primary .mat-button-focus-overlay, .app.gray-light .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.gray-light .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #616161;
}
.app.gray-light .mat-button.mat-accent .mat-button-focus-overlay, .app.gray-light .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.gray-light .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.gray-light .mat-button.mat-warn .mat-button-focus-overlay, .app.gray-light .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.gray-light .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.gray-light .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.gray-light .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.gray-light .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.gray-light .mat-button .mat-ripple-element, .app.gray-light .mat-icon-button .mat-ripple-element, .app.gray-light .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.gray-light .mat-button-focus-overlay {
  background: black;
}
.app.gray-light .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-flat-button, .app.gray-light .mat-raised-button, .app.gray-light .mat-fab, .app.gray-light .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.app.gray-light .mat-flat-button.mat-primary, .app.gray-light .mat-raised-button.mat-primary, .app.gray-light .mat-fab.mat-primary, .app.gray-light .mat-mini-fab.mat-primary {
  color: white;
}
.app.gray-light .mat-flat-button.mat-accent, .app.gray-light .mat-raised-button.mat-accent, .app.gray-light .mat-fab.mat-accent, .app.gray-light .mat-mini-fab.mat-accent {
  color: white;
}
.app.gray-light .mat-flat-button.mat-warn, .app.gray-light .mat-raised-button.mat-warn, .app.gray-light .mat-fab.mat-warn, .app.gray-light .mat-mini-fab.mat-warn {
  color: white;
}
.app.gray-light .mat-flat-button.mat-primary.mat-button-disabled, .app.gray-light .mat-flat-button.mat-accent.mat-button-disabled, .app.gray-light .mat-flat-button.mat-warn.mat-button-disabled, .app.gray-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-raised-button.mat-primary.mat-button-disabled, .app.gray-light .mat-raised-button.mat-accent.mat-button-disabled, .app.gray-light .mat-raised-button.mat-warn.mat-button-disabled, .app.gray-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-fab.mat-primary.mat-button-disabled, .app.gray-light .mat-fab.mat-accent.mat-button-disabled, .app.gray-light .mat-fab.mat-warn.mat-button-disabled, .app.gray-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-flat-button.mat-primary, .app.gray-light .mat-raised-button.mat-primary, .app.gray-light .mat-fab.mat-primary, .app.gray-light .mat-mini-fab.mat-primary {
  background-color: #616161;
}
.app.gray-light .mat-flat-button.mat-accent, .app.gray-light .mat-raised-button.mat-accent, .app.gray-light .mat-fab.mat-accent, .app.gray-light .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.gray-light .mat-flat-button.mat-warn, .app.gray-light .mat-raised-button.mat-warn, .app.gray-light .mat-fab.mat-warn, .app.gray-light .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.gray-light .mat-flat-button.mat-primary.mat-button-disabled, .app.gray-light .mat-flat-button.mat-accent.mat-button-disabled, .app.gray-light .mat-flat-button.mat-warn.mat-button-disabled, .app.gray-light .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-raised-button.mat-primary.mat-button-disabled, .app.gray-light .mat-raised-button.mat-accent.mat-button-disabled, .app.gray-light .mat-raised-button.mat-warn.mat-button-disabled, .app.gray-light .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-fab.mat-primary.mat-button-disabled, .app.gray-light .mat-fab.mat-accent.mat-button-disabled, .app.gray-light .mat-fab.mat-warn.mat-button-disabled, .app.gray-light .mat-fab.mat-button-disabled.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-primary.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-accent.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-warn.mat-button-disabled, .app.gray-light .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-flat-button.mat-primary .mat-ripple-element, .app.gray-light .mat-raised-button.mat-primary .mat-ripple-element, .app.gray-light .mat-fab.mat-primary .mat-ripple-element, .app.gray-light .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-flat-button.mat-accent .mat-ripple-element, .app.gray-light .mat-raised-button.mat-accent .mat-ripple-element, .app.gray-light .mat-fab.mat-accent .mat-ripple-element, .app.gray-light .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-flat-button.mat-warn .mat-ripple-element, .app.gray-light .mat-raised-button.mat-warn .mat-ripple-element, .app.gray-light .mat-fab.mat-warn .mat-ripple-element, .app.gray-light .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-stroked-button:not([class*=mat-elevation-z]), .app.gray-light .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-fab:not([class*=mat-elevation-z]), .app.gray-light .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.gray-light .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.gray-light .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.gray-light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.gray-light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.gray-light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.app.gray-light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.app.gray-light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.app.gray-light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.app.gray-light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.app.gray-light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.app.gray-light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.app.gray-light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.app.gray-light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.gray-light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.app.gray-light .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-checkbox-checkmark {
  fill: #fafafa;
}
.app.gray-light .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.app.gray-light .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.app.gray-light .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.gray-light .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #616161;
}
.app.gray-light .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.gray-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.gray-light .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.gray-light .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.gray-light .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.gray-light .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.app.gray-light .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.app.gray-light .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.app.gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #616161;
}
.app.gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.gray-light .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.gray-light .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.gray-light .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.gray-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.gray-light .mat-chip.mat-standard-chip::after {
  background: black;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #616161;
  color: white;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-light .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-light .mat-table {
  background: white;
}
.app.gray-light .mat-table thead, .app.gray-light .mat-table tbody, .app.gray-light .mat-table tfoot,
.app.gray-light mat-header-row, .app.gray-light mat-row, .app.gray-light mat-footer-row,
.app.gray-light [mat-header-row], .app.gray-light [mat-row], .app.gray-light [mat-footer-row],
.app.gray-light .mat-table-sticky {
  background: inherit;
}
.app.gray-light mat-row, .app.gray-light mat-header-row, .app.gray-light mat-footer-row,
.app.gray-light th.mat-header-cell, .app.gray-light td.mat-cell, .app.gray-light td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-cell, .app.gray-light .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-datepicker-toggle,
.app.gray-light .mat-datepicker-content .mat-calendar-next-button,
.app.gray-light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-calendar-table-header,
.app.gray-light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-calendar-body-cell-content,
.app.gray-light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.app.gray-light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.app.gray-light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.app.gray-light .mat-calendar-body-in-range::before {
  background: rgba(97, 97, 97, 0.2);
}
.app.gray-light .mat-calendar-body-comparison-identical,
.app.gray-light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-light .mat-calendar-body-comparison-bridge-start::before,
.app.gray-light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-calendar-body-comparison-bridge-end::before,
.app.gray-light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(97, 97, 97, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-light .mat-calendar-body-selected {
  background-color: #616161;
  color: white;
}
.app.gray-light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.gray-light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(97, 97, 97, 0.3);
}
@media (hover: hover) {
  .app.gray-light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(97, 97, 97, 0.3);
  }
}
.app.gray-light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.gray-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.gray-light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .app.gray-light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.gray-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.gray-light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.gray-light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.gray-light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-datepicker-toggle-active {
  color: #616161;
}
.app.gray-light .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.gray-light .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.gray-light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.gray-light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.gray-light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .app.gray-light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.app.gray-light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-expansion-panel-header-description,
.app.gray-light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.gray-light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.gray-light .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.app.gray-light .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-label {
  color: #616161;
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.gray-light .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.gray-light .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #616161;
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.gray-light .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #616161;
}
.app.gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.gray-light .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.gray-light .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.gray-light .mat-error {
  color: #f44336;
}
.app.gray-light .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.gray-light .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.gray-light .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.app.gray-light .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.gray-light .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #616161;
}
.app.gray-light .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.gray-light .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.gray-light .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.gray-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.app.gray-light .mat-icon.mat-primary {
  color: #616161;
}
.app.gray-light .mat-icon.mat-accent {
  color: #ff4081;
}
.app.gray-light .mat-icon.mat-warn {
  color: #f44336;
}
.app.gray-light .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-input-element:disabled,
.app.gray-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-input-element {
  caret-color: #616161;
}
.app.gray-light .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.gray-light .mat-form-field.mat-warn .mat-input-element,
.app.gray-light .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.gray-light .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.gray-light .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-list-option:hover, .app.gray-light .mat-list-option:focus,
.app.gray-light .mat-nav-list .mat-list-item:hover,
.app.gray-light .mat-nav-list .mat-list-item:focus,
.app.gray-light .mat-action-list .mat-list-item:hover,
.app.gray-light .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-list-single-selected-option, .app.gray-light .mat-list-single-selected-option:hover, .app.gray-light .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-menu-panel {
  background: white;
}
.app.gray-light .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-menu-item[disabled],
.app.gray-light .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.gray-light .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-menu-item .mat-icon-no-color,
.app.gray-light .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-menu-item:hover:not([disabled]),
.app.gray-light .mat-menu-item.cdk-program-focused:not([disabled]),
.app.gray-light .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.gray-light .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-paginator {
  background: white;
}
.app.gray-light .mat-paginator,
.app.gray-light .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-paginator-decrement,
.app.gray-light .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-paginator-first,
.app.gray-light .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-icon-button[disabled] .mat-paginator-decrement,
.app.gray-light .mat-icon-button[disabled] .mat-paginator-increment,
.app.gray-light .mat-icon-button[disabled] .mat-paginator-first,
.app.gray-light .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-progress-bar-background {
  fill: #d4d4d4;
}
.app.gray-light .mat-progress-bar-buffer {
  background-color: #d4d4d4;
}
.app.gray-light .mat-progress-bar-fill::after {
  background-color: #616161;
}
.app.gray-light .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.app.gray-light .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.app.gray-light .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.gray-light .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.app.gray-light .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.app.gray-light .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.gray-light .mat-progress-spinner circle, .app.gray-light .mat-spinner circle {
  stroke: #616161;
}
.app.gray-light .mat-progress-spinner.mat-accent circle, .app.gray-light .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.gray-light .mat-progress-spinner.mat-warn circle, .app.gray-light .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.gray-light .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #616161;
}
.app.gray-light .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.gray-light .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-light .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-light .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #616161;
}
.app.gray-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.gray-light .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.gray-light .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-light .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-light .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.gray-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.gray-light .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.gray-light .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-light .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-light .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.gray-light .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.gray-light .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.gray-light .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.app.gray-light .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.app.gray-light .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-select-panel {
  background: white;
}
.app.gray-light .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #616161;
}
.app.gray-light .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.gray-light .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.gray-light .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.gray-light .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-drawer.mat-drawer-push {
  background-color: white;
}
.app.gray-light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.gray-light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.app.gray-light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.app.gray-light .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.gray-light .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.gray-light .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #616161;
}
.app.gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(97, 97, 97, 0.54);
}
.app.gray-light .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #616161;
}
.app.gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.gray-light .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.gray-light .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.app.gray-light .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.app.gray-light .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-slider.mat-primary .mat-slider-track-fill,
.app.gray-light .mat-slider.mat-primary .mat-slider-thumb,
.app.gray-light .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #616161;
}
.app.gray-light .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-light .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(97, 97, 97, 0.2);
}
.app.gray-light .mat-slider.mat-accent .mat-slider-track-fill,
.app.gray-light .mat-slider.mat-accent .mat-slider-thumb,
.app.gray-light .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.gray-light .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-light .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.gray-light .mat-slider.mat-warn .mat-slider-track-fill,
.app.gray-light .mat-slider.mat-warn .mat-slider-thumb,
.app.gray-light .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.gray-light .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-light .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.gray-light .mat-slider:hover .mat-slider-track-background,
.app.gray-light .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.gray-light .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.gray-light .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.gray-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.gray-light .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.app.gray-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.gray-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.gray-light .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.app.gray-light .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.app.gray-light .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.gray-light .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.app.gray-light .mat-step-header.cdk-keyboard-focused, .app.gray-light .mat-step-header.cdk-program-focused, .app.gray-light .mat-step-header:hover:not([aria-disabled]), .app.gray-light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.app.gray-light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.gray-light .mat-step-header:hover {
    background: none;
  }
}
.app.gray-light .mat-step-header .mat-step-label,
.app.gray-light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.app.gray-light .mat-step-header .mat-step-icon-selected,
.app.gray-light .mat-step-header .mat-step-icon-state-done,
.app.gray-light .mat-step-header .mat-step-icon-state-edit {
  background-color: #616161;
  color: white;
}
.app.gray-light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.gray-light .mat-step-header.mat-accent .mat-step-icon-selected,
.app.gray-light .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.gray-light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.app.gray-light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.gray-light .mat-step-header.mat-warn .mat-step-icon-selected,
.app.gray-light .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.gray-light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.gray-light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.gray-light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.gray-light .mat-stepper-horizontal, .app.gray-light .mat-stepper-vertical {
  background-color: white;
}
.app.gray-light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-horizontal-stepper-header::before,
.app.gray-light .mat-horizontal-stepper-header::after,
.app.gray-light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-sort-header-arrow {
  color: #757575;
}
.app.gray-light .mat-tab-nav-bar,
.app.gray-light .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.gray-light .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.app.gray-light .mat-tab-label, .app.gray-light .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.app.gray-light .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.gray-light .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.gray-light .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 250, 250, 0.3);
}
.app.gray-light .mat-tab-group.mat-primary .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #616161;
}
.app.gray-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-light .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.gray-light .mat-tab-group.mat-accent .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.gray-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-light .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.gray-light .mat-tab-group.mat-warn .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.gray-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.gray-light .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(250, 250, 250, 0.3);
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header, .app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #616161;
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header, .app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-light .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header, .app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.gray-light .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-toolbar.mat-primary {
  background: #616161;
  color: white;
}
.app.gray-light .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.gray-light .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.gray-light .mat-toolbar .mat-form-field-underline,
.app.gray-light .mat-toolbar .mat-form-field-ripple,
.app.gray-light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.gray-light .mat-toolbar .mat-form-field-label,
.app.gray-light .mat-toolbar .mat-focused .mat-form-field-label,
.app.gray-light .mat-toolbar .mat-select-value,
.app.gray-light .mat-toolbar .mat-select-arrow,
.app.gray-light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.gray-light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.gray-light .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.gray-light .mat-tree {
  background: white;
}
.app.gray-light .mat-tree-node,
.app.gray-light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-light .mat-simple-snackbar-action {
  color: #ff4081;
}
.app.gray-light .bg-primary {
  background: #616161;
  color: white;
}
.app.gray-light .bg-accent {
  background: #ff4081;
  color: white;
}
.app.gray-light .bg-warn {
  background: #f44336;
  color: white;
}
.app.gray-light .secondary-text-color {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .primary-color {
  color: #616161 !important;
}
.app.gray-light .accent-color {
  color: #ff4081 !important;
}
.app.gray-light .warn-color {
  color: #f44336 !important;
}
.app.gray-light .header {
  background: white;
  color: black;
}
.app.gray-light .header .info-content .top-contact-form {
  border-color: rgba(0, 0, 0, 0.12);
}
.app.gray-light .content-header h4 {
  color: #fafafa;
}
.app.gray-light .favorites-container:after {
  color: #ff4081;
}
.app.gray-light .horizontal-menu {
  background: white;
}
.app.gray-light .horizontal-menu-item .horizontal-sub-menu {
  background: white;
}
.app.gray-light .horizontal-menu-item .mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.gray-light .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #616161;
  color: #fff;
}
.app.gray-light .menu-item .mat-button .menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .menu-item .mat-button .menu-title {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .menu-item .mat-button.active-link {
  background: rgba(0, 0, 0, 0.12);
}
.app.gray-light .menu-item .mat-button.active-link .menu-icon {
  background: #616161;
  color: #fff;
}
.app.gray-light .menu-expand-icon {
  color: rgba(0, 0, 0, 0.54);
}
.app.gray-light .breadcrumb a {
  color: #fafafa;
}
.app.gray-light .top-toolbar, .app.gray-light .landing-toolbar {
  background: #424242;
  color: #fafafa;
}
.app.gray-light .menu-toolbar {
  background: #212121;
  color: whitesmoke;
}
.app.gray-light .ps__thumb-y {
  background-color: #616161;
}
.app.gray-light .testimonial-item, .app.gray-light .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.gray-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.gray-light [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.gray-light .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.gray-light .annular-pagination .ngx-pagination .current {
  background: #616161;
  color: white;
}
.app.gray-light .annular-pagination .ngx-pagination a:hover, .app.gray-light .annular-pagination .ngx-pagination button:hover {
  background: rgba(97, 97, 97, 0.2);
  color: black;
}
.app.gray-light .cal-day-view .cal-hour-segment:hover,
.app.gray-light .cal-day-view .cal-drag-over .cal-hour-segment,
.app.gray-light .cal-week-view .cal-day-headers .cal-header:hover,
.app.gray-light .cal-week-view .cal-day-headers .cal-drag-over,
.app.gray-light .cal-month-view .cal-cell-row .cal-cell:hover,
.app.gray-light .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.gray-light .cal-month-view .cal-cell-row:hover {
  background-color: rgba(97, 97, 97, 0.05);
}
.app.gray-light .cal-week-view .cal-header.cal-today,
.app.gray-light .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(97, 97, 97, 0.4);
}
.app.gray-light .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.gray-light .cal-event-action i {
  margin: 4px;
}
.app.gray-light .ql-snow .ql-picker,
.app.gray-light .ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-light .ql-snow .ql-stroke {
  stroke: rgba(0, 0, 0, 0.87);
}
.app.gray-light .ql-snow .ql-fill {
  fill: rgba(0, 0, 0, 0.87);
}
.app.gray-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.gray-light .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: whitesmoke;
}
.app.gray-light .ngx-charts text {
  fill: rgba(0, 0, 0, 0.87);
}
.app.blue-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.blue-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-option {
  color: white;
}
.app.blue-dark .mat-option:hover:not(.mat-option-disabled), .app.blue-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.blue-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.blue-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.blue-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0288d1;
}
.app.blue-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app.blue-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.blue-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.blue-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.blue-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.blue-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0288d1;
}
.app.blue-dark .mat-pseudo-checkbox-checked,
.app.blue-dark .mat-pseudo-checkbox-indeterminate,
.app.blue-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.blue-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app.blue-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.blue-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.blue-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.blue-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.blue-dark .mat-app-background, .app.blue-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.blue-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.blue-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.blue-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.blue-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.blue-dark .mat-badge {
  position: relative;
}
.app.blue-dark .mat-badge.mat-badge {
  overflow: visible;
}
.app.blue-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.blue-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.blue-dark .ng-animate-disabled .mat-badge-content,
.app.blue-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.blue-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.blue-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.blue-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.blue-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.blue-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.blue-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.blue-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.blue-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.blue-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.blue-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.blue-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.blue-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.blue-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.blue-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.blue-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.blue-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.blue-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.blue-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.blue-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.blue-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.blue-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.blue-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.blue-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.blue-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.blue-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.blue-dark .mat-badge-content {
  color: white;
  background: #0288d1;
}
.cdk-high-contrast-active .app.blue-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.blue-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.blue-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.blue-dark .mat-button, .app.blue-dark .mat-icon-button, .app.blue-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.blue-dark .mat-button.mat-primary, .app.blue-dark .mat-icon-button.mat-primary, .app.blue-dark .mat-stroked-button.mat-primary {
  color: #0288d1;
}
.app.blue-dark .mat-button.mat-accent, .app.blue-dark .mat-icon-button.mat-accent, .app.blue-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app.blue-dark .mat-button.mat-warn, .app.blue-dark .mat-icon-button.mat-warn, .app.blue-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.blue-dark .mat-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.blue-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.blue-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0288d1;
}
.app.blue-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.blue-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.blue-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app.blue-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.blue-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.blue-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.blue-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.blue-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.blue-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.blue-dark .mat-button .mat-ripple-element, .app.blue-dark .mat-icon-button .mat-ripple-element, .app.blue-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.blue-dark .mat-button-focus-overlay {
  background: white;
}
.app.blue-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-flat-button, .app.blue-dark .mat-raised-button, .app.blue-dark .mat-fab, .app.blue-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.blue-dark .mat-flat-button.mat-primary, .app.blue-dark .mat-raised-button.mat-primary, .app.blue-dark .mat-fab.mat-primary, .app.blue-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.blue-dark .mat-flat-button.mat-accent, .app.blue-dark .mat-raised-button.mat-accent, .app.blue-dark .mat-fab.mat-accent, .app.blue-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-flat-button.mat-warn, .app.blue-dark .mat-raised-button.mat-warn, .app.blue-dark .mat-fab.mat-warn, .app.blue-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.blue-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-fab.mat-primary.mat-button-disabled, .app.blue-dark .mat-fab.mat-accent.mat-button-disabled, .app.blue-dark .mat-fab.mat-warn.mat-button-disabled, .app.blue-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-flat-button.mat-primary, .app.blue-dark .mat-raised-button.mat-primary, .app.blue-dark .mat-fab.mat-primary, .app.blue-dark .mat-mini-fab.mat-primary {
  background-color: #0288d1;
}
.app.blue-dark .mat-flat-button.mat-accent, .app.blue-dark .mat-raised-button.mat-accent, .app.blue-dark .mat-fab.mat-accent, .app.blue-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app.blue-dark .mat-flat-button.mat-warn, .app.blue-dark .mat-raised-button.mat-warn, .app.blue-dark .mat-fab.mat-warn, .app.blue-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.blue-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.blue-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-fab.mat-primary.mat-button-disabled, .app.blue-dark .mat-fab.mat-accent.mat-button-disabled, .app.blue-dark .mat-fab.mat-warn.mat-button-disabled, .app.blue-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.blue-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.blue-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.blue-dark .mat-fab.mat-primary .mat-ripple-element, .app.blue-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.blue-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.blue-dark .mat-fab.mat-accent .mat-ripple-element, .app.blue-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.blue-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.blue-dark .mat-fab.mat-warn .mat-ripple-element, .app.blue-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.blue-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-fab:not([class*=mat-elevation-z]), .app.blue-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.blue-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.blue-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.blue-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.blue-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.blue-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.blue-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.blue-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.app.blue-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.app.blue-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.app.blue-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.blue-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.blue-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.blue-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.blue-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.blue-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.app.blue-dark .mat-card {
  background: #424242;
  color: white;
}
.app.blue-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.blue-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.blue-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.blue-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.blue-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0288d1;
}
.app.blue-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.blue-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app.blue-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.blue-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.blue-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.blue-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.blue-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.blue-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0288d1;
}
.app.blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app.blue-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.blue-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.blue-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.blue-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.blue-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0288d1;
  color: white;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.blue-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.blue-dark .mat-table {
  background: #424242;
}
.app.blue-dark .mat-table thead, .app.blue-dark .mat-table tbody, .app.blue-dark .mat-table tfoot,
.app.blue-dark mat-header-row, .app.blue-dark mat-row, .app.blue-dark mat-footer-row,
.app.blue-dark [mat-header-row], .app.blue-dark [mat-row], .app.blue-dark [mat-footer-row],
.app.blue-dark .mat-table-sticky {
  background: inherit;
}
.app.blue-dark mat-row, .app.blue-dark mat-header-row, .app.blue-dark mat-footer-row,
.app.blue-dark th.mat-header-cell, .app.blue-dark td.mat-cell, .app.blue-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-cell, .app.blue-dark .mat-footer-cell {
  color: white;
}
.app.blue-dark .mat-calendar-arrow {
  fill: white;
}
.app.blue-dark .mat-datepicker-toggle,
.app.blue-dark .mat-datepicker-content .mat-calendar-next-button,
.app.blue-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.blue-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-calendar-table-header,
.app.blue-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-calendar-body-cell-content,
.app.blue-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.blue-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.blue-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-calendar-body-in-range::before {
  background: rgba(2, 136, 209, 0.2);
}
.app.blue-dark .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue-dark .mat-calendar-body-comparison-bridge-start::before,
.app.blue-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-calendar-body-comparison-bridge-end::before,
.app.blue-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(2, 136, 209, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue-dark .mat-calendar-body-selected {
  background-color: #0288d1;
  color: white;
}
.app.blue-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.blue-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(2, 136, 209, 0.3);
}
@media (hover: hover) {
  .app.blue-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(2, 136, 209, 0.3);
  }
}
.app.blue-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.blue-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.blue-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}
@media (hover: hover) {
  .app.blue-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 215, 64, 0.3);
  }
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.blue-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.blue-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.blue-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.blue-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.blue-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.blue-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-datepicker-toggle-active {
  color: #0288d1;
}
.app.blue-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app.blue-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.blue-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.blue-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.blue-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.blue-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.blue-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.blue-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.blue-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.blue-dark .mat-expansion-panel-header-description,
.app.blue-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.blue-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.blue-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #0288d1;
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.blue-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app.blue-dark .mat-form-field-ripple {
  background-color: white;
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0288d1;
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app.blue-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0288d1;
}
.app.blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app.blue-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.blue-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.blue-dark .mat-error {
  color: #ff5252;
}
.app.blue-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.blue-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.blue-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.blue-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.blue-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.blue-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0288d1;
}
.app.blue-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app.blue-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.blue-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.blue-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.blue-dark .mat-icon.mat-primary {
  color: #0288d1;
}
.app.blue-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app.blue-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.blue-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-input-element:disabled,
.app.blue-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-input-element {
  caret-color: #0288d1;
}
.app.blue-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.blue-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app.blue-dark .mat-form-field.mat-warn .mat-input-element,
.app.blue-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.blue-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.blue-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.blue-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.blue-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-list-option:hover, .app.blue-dark .mat-list-option:focus,
.app.blue-dark .mat-nav-list .mat-list-item:hover,
.app.blue-dark .mat-nav-list .mat-list-item:focus,
.app.blue-dark .mat-action-list .mat-list-item:hover,
.app.blue-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.blue-dark .mat-list-single-selected-option, .app.blue-dark .mat-list-single-selected-option:hover, .app.blue-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-menu-panel {
  background: #424242;
}
.app.blue-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.blue-dark .mat-menu-item[disabled],
.app.blue-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.blue-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-menu-item .mat-icon-no-color,
.app.blue-dark .mat-menu-submenu-icon {
  color: white;
}
.app.blue-dark .mat-menu-item:hover:not([disabled]),
.app.blue-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.blue-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.blue-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.blue-dark .mat-paginator {
  background: #424242;
}
.app.blue-dark .mat-paginator,
.app.blue-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-paginator-decrement,
.app.blue-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.blue-dark .mat-paginator-first,
.app.blue-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.blue-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.blue-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.blue-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.blue-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-progress-bar-background {
  fill: #254658;
}
.app.blue-dark .mat-progress-bar-buffer {
  background-color: #254658;
}
.app.blue-dark .mat-progress-bar-fill::after {
  background-color: #0288d1;
}
.app.blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645a34;
}
.app.blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645a34;
}
.app.blue-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app.blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643939;
}
.app.blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643939;
}
.app.blue-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.blue-dark .mat-progress-spinner circle, .app.blue-dark .mat-spinner circle {
  stroke: #0288d1;
}
.app.blue-dark .mat-progress-spinner.mat-accent circle, .app.blue-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app.blue-dark .mat-progress-spinner.mat-warn circle, .app.blue-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.blue-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0288d1;
}
.app.blue-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.blue-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.blue-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0288d1;
}
.app.blue-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app.blue-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.blue-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.blue-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app.blue-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.blue-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.blue-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.blue-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.blue-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.blue-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.blue-dark .mat-select-value {
  color: white;
}
.app.blue-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-select-panel {
  background: #424242;
}
.app.blue-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0288d1;
}
.app.blue-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app.blue-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.blue-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.blue-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.blue-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.blue-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.blue-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.blue-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.blue-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.blue-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app.blue-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app.blue-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app.blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0288d1;
}
.app.blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(2, 136, 209, 0.54);
}
.app.blue-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0288d1;
}
.app.blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.blue-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.blue-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.blue-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.blue-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app.blue-dark .mat-slider.mat-primary .mat-slider-thumb,
.app.blue-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0288d1;
}
.app.blue-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.blue-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(2, 136, 209, 0.2);
}
.app.blue-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app.blue-dark .mat-slider.mat-accent .mat-slider-thumb,
.app.blue-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app.blue-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app.blue-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app.blue-dark .mat-slider.mat-warn .mat-slider-thumb,
.app.blue-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.blue-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.blue-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.blue-dark .mat-slider:hover .mat-slider-track-background,
.app.blue-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.blue-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.blue-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.blue-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.blue-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.blue-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.blue-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.blue-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.blue-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.blue-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.blue-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.blue-dark .mat-step-header.cdk-keyboard-focused, .app.blue-dark .mat-step-header.cdk-program-focused, .app.blue-dark .mat-step-header:hover:not([aria-disabled]), .app.blue-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.blue-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.blue-dark .mat-step-header:hover {
    background: none;
  }
}
.app.blue-dark .mat-step-header .mat-step-label,
.app.blue-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.blue-dark .mat-step-header .mat-step-icon-selected,
.app.blue-dark .mat-step-header .mat-step-icon-state-done,
.app.blue-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #0288d1;
  color: white;
}
.app.blue-dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app.blue-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.blue-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.blue-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app.blue-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.blue-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.blue-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.blue-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.blue-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.blue-dark .mat-stepper-horizontal, .app.blue-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.blue-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-horizontal-stepper-header::before,
.app.blue-dark .mat-horizontal-stepper-header::after,
.app.blue-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.blue-dark .mat-tab-nav-bar,
.app.blue-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.blue-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.blue-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.blue-dark .mat-tab-label, .app.blue-dark .mat-tab-link {
  color: white;
}
.app.blue-dark .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.blue-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.blue-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.blue-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.blue-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.blue-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0288d1;
}
.app.blue-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.blue-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.blue-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app.blue-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.blue-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.blue-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.blue-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.blue-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 245, 254, 0.3);
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0288d1;
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.blue-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.app.blue-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.blue-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.blue-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.blue-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.blue-dark .mat-toolbar.mat-primary {
  background: #0288d1;
  color: white;
}
.app.blue-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.blue-dark .mat-toolbar .mat-form-field-underline,
.app.blue-dark .mat-toolbar .mat-form-field-ripple,
.app.blue-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.blue-dark .mat-toolbar .mat-form-field-label,
.app.blue-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.blue-dark .mat-toolbar .mat-select-value,
.app.blue-dark .mat-toolbar .mat-select-arrow,
.app.blue-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.blue-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.blue-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.blue-dark .mat-tree {
  background: #424242;
}
.app.blue-dark .mat-tree-node,
.app.blue-dark .mat-nested-tree-node {
  color: white;
}
.app.blue-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.blue-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.blue-dark .bg-primary {
  background: #0288d1;
  color: white;
}
.app.blue-dark .bg-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.blue-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.blue-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .primary-color {
  color: #0288d1 !important;
}
.app.blue-dark .accent-color {
  color: #ffd740 !important;
}
.app.blue-dark .warn-color {
  color: #ff5252 !important;
}
.app.blue-dark .header {
  background: #424242;
  color: white;
}
.app.blue-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .content-header h4 {
  color: #e1f5fe;
}
.app.blue-dark .favorites-container:after {
  color: #ffd740;
}
.app.blue-dark .horizontal-menu {
  background: #424242;
}
.app.blue-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.blue-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.blue-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.blue-dark .menu-item .mat-button .menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.blue-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.blue-dark .menu-item .mat-button.active-link .menu-icon {
  background: #0288d1;
  color: #fff;
}
.app.blue-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.blue-dark .breadcrumb a {
  color: #e1f5fe;
}
.app.blue-dark .top-toolbar, .app.blue-dark .landing-toolbar {
  background: #0277bd;
  color: #e1f5fe;
}
.app.blue-dark .menu-toolbar {
  background: #01579b;
  color: #b3e5fc;
}
.app.blue-dark .ps__thumb-y {
  background-color: #0288d1;
}
.app.blue-dark .testimonial-item, .app.blue-dark .pricing-card {
  border-top: 4px solid #ffd740;
}
.app.blue-dark .ngx-charts text {
  fill: white;
}
.app.blue-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.blue-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.blue-dark .annular-pagination .ngx-pagination .current {
  background: #0288d1;
  color: white;
}
.app.blue-dark .annular-pagination .ngx-pagination a:hover, .app.blue-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(2, 136, 209, 0.2);
  color: white;
}
.app.blue-dark .cal-day-view .cal-hour-segment:hover,
.app.blue-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.blue-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.blue-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.blue-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.blue-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.blue-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(2, 136, 209, 0.05);
}
.app.blue-dark .cal-week-view .cal-header.cal-today,
.app.blue-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(2, 136, 209, 0.4);
}
.app.blue-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.blue-dark .cal-event-action i {
  margin: 4px;
}
.app.blue-dark .ql-snow .ql-picker,
.app.blue-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.blue-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.blue-dark .ql-snow .ql-fill {
  fill: white;
}
.app.blue-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.blue-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.blue-dark .ngx-charts text {
  fill: white;
}
.app.green-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.green-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-option {
  color: white;
}
.app.green-dark .mat-option:hover:not(.mat-option-disabled), .app.green-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.green-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.green-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.green-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #388e3c;
}
.app.green-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd740;
}
.app.green-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.green-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.green-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.green-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.green-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #388e3c;
}
.app.green-dark .mat-pseudo-checkbox-checked,
.app.green-dark .mat-pseudo-checkbox-indeterminate,
.app.green-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.green-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd740;
}
.app.green-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.green-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.green-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.green-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.green-dark .mat-app-background, .app.green-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.green-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.green-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.green-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.green-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.green-dark .mat-badge {
  position: relative;
}
.app.green-dark .mat-badge.mat-badge {
  overflow: visible;
}
.app.green-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.green-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.green-dark .ng-animate-disabled .mat-badge-content,
.app.green-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.green-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.green-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.green-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.green-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.green-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.green-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.green-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.green-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.green-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.green-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.green-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.green-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.green-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.green-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.green-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.green-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.green-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.green-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.green-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.green-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.green-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.green-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.green-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.green-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.green-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.green-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.green-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.green-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.green-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.green-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.green-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.green-dark .mat-badge-content {
  color: white;
  background: #388e3c;
}
.cdk-high-contrast-active .app.green-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.green-dark .mat-badge-accent .mat-badge-content {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.green-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.green-dark .mat-button, .app.green-dark .mat-icon-button, .app.green-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.green-dark .mat-button.mat-primary, .app.green-dark .mat-icon-button.mat-primary, .app.green-dark .mat-stroked-button.mat-primary {
  color: #388e3c;
}
.app.green-dark .mat-button.mat-accent, .app.green-dark .mat-icon-button.mat-accent, .app.green-dark .mat-stroked-button.mat-accent {
  color: #ffd740;
}
.app.green-dark .mat-button.mat-warn, .app.green-dark .mat-icon-button.mat-warn, .app.green-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.green-dark .mat-button.mat-primary.mat-button-disabled, .app.green-dark .mat-button.mat-accent.mat-button-disabled, .app.green-dark .mat-button.mat-warn.mat-button-disabled, .app.green-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.green-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.green-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.green-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.green-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.green-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.green-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.green-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.green-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #388e3c;
}
.app.green-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.green-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.green-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd740;
}
.app.green-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.green-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.green-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.green-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.green-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.green-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.green-dark .mat-button .mat-ripple-element, .app.green-dark .mat-icon-button .mat-ripple-element, .app.green-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.green-dark .mat-button-focus-overlay {
  background: white;
}
.app.green-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-flat-button, .app.green-dark .mat-raised-button, .app.green-dark .mat-fab, .app.green-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.green-dark .mat-flat-button.mat-primary, .app.green-dark .mat-raised-button.mat-primary, .app.green-dark .mat-fab.mat-primary, .app.green-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.green-dark .mat-flat-button.mat-accent, .app.green-dark .mat-raised-button.mat-accent, .app.green-dark .mat-fab.mat-accent, .app.green-dark .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-flat-button.mat-warn, .app.green-dark .mat-raised-button.mat-warn, .app.green-dark .mat-fab.mat-warn, .app.green-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.green-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.green-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.green-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.green-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.green-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.green-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.green-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-fab.mat-primary.mat-button-disabled, .app.green-dark .mat-fab.mat-accent.mat-button-disabled, .app.green-dark .mat-fab.mat-warn.mat-button-disabled, .app.green-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-flat-button.mat-primary, .app.green-dark .mat-raised-button.mat-primary, .app.green-dark .mat-fab.mat-primary, .app.green-dark .mat-mini-fab.mat-primary {
  background-color: #388e3c;
}
.app.green-dark .mat-flat-button.mat-accent, .app.green-dark .mat-raised-button.mat-accent, .app.green-dark .mat-fab.mat-accent, .app.green-dark .mat-mini-fab.mat-accent {
  background-color: #ffd740;
}
.app.green-dark .mat-flat-button.mat-warn, .app.green-dark .mat-raised-button.mat-warn, .app.green-dark .mat-fab.mat-warn, .app.green-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.green-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.green-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.green-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.green-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.green-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.green-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.green-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-fab.mat-primary.mat-button-disabled, .app.green-dark .mat-fab.mat-accent.mat-button-disabled, .app.green-dark .mat-fab.mat-warn.mat-button-disabled, .app.green-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.green-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.green-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.green-dark .mat-fab.mat-primary .mat-ripple-element, .app.green-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.green-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.green-dark .mat-fab.mat-accent .mat-ripple-element, .app.green-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.green-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.green-dark .mat-fab.mat-warn .mat-ripple-element, .app.green-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.green-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-fab:not([class*=mat-elevation-z]), .app.green-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.green-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.green-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.green-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.green-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.green-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.green-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.green-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.app.green-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.app.green-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.app.green-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.green-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.green-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.green-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.green-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.green-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.app.green-dark .mat-card {
  background: #424242;
  color: white;
}
.app.green-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.green-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.green-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.green-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.green-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #388e3c;
}
.app.green-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.green-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd740;
}
.app.green-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.green-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.green-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.green-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.green-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.green-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #388e3c;
}
.app.green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd740;
}
.app.green-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.green-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.green-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.green-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.green-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #388e3c;
  color: white;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.app.green-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.app.green-dark .mat-table {
  background: #424242;
}
.app.green-dark .mat-table thead, .app.green-dark .mat-table tbody, .app.green-dark .mat-table tfoot,
.app.green-dark mat-header-row, .app.green-dark mat-row, .app.green-dark mat-footer-row,
.app.green-dark [mat-header-row], .app.green-dark [mat-row], .app.green-dark [mat-footer-row],
.app.green-dark .mat-table-sticky {
  background: inherit;
}
.app.green-dark mat-row, .app.green-dark mat-header-row, .app.green-dark mat-footer-row,
.app.green-dark th.mat-header-cell, .app.green-dark td.mat-cell, .app.green-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-cell, .app.green-dark .mat-footer-cell {
  color: white;
}
.app.green-dark .mat-calendar-arrow {
  fill: white;
}
.app.green-dark .mat-datepicker-toggle,
.app.green-dark .mat-datepicker-content .mat-calendar-next-button,
.app.green-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.green-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-calendar-table-header,
.app.green-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-calendar-body-cell-content,
.app.green-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.green-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.green-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-calendar-body-in-range::before {
  background: rgba(56, 142, 60, 0.2);
}
.app.green-dark .mat-calendar-body-comparison-identical,
.app.green-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green-dark .mat-calendar-body-comparison-bridge-start::before,
.app.green-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-calendar-body-comparison-bridge-end::before,
.app.green-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(56, 142, 60, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green-dark .mat-calendar-body-selected {
  background-color: #388e3c;
  color: white;
}
.app.green-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.green-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(56, 142, 60, 0.3);
}
@media (hover: hover) {
  .app.green-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(56, 142, 60, 0.3);
  }
}
.app.green-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 215, 64, 0.2);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.green-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.green-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 215, 64, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 215, 64, 0.4);
}
.app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 215, 64, 0.3);
}
@media (hover: hover) {
  .app.green-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 215, 64, 0.3);
  }
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.green-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.green-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.green-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.green-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.green-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.green-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-datepicker-toggle-active {
  color: #388e3c;
}
.app.green-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ffd740;
}
.app.green-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.green-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.green-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.green-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.green-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.green-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.green-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.green-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.green-dark .mat-expansion-panel-header-description,
.app.green-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.green-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.green-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #388e3c;
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd740;
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.green-dark .mat-focused .mat-form-field-required-marker {
  color: #ffd740;
}
.app.green-dark .mat-form-field-ripple {
  background-color: white;
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #388e3c;
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd740;
}
.app.green-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #388e3c;
}
.app.green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd740;
}
.app.green-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.green-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.green-dark .mat-error {
  color: #ff5252;
}
.app.green-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.green-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.green-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.green-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.green-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.green-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #388e3c;
}
.app.green-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd740;
}
.app.green-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.green-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.green-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.green-dark .mat-icon.mat-primary {
  color: #388e3c;
}
.app.green-dark .mat-icon.mat-accent {
  color: #ffd740;
}
.app.green-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.green-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-input-element:disabled,
.app.green-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-input-element {
  caret-color: #388e3c;
}
.app.green-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.green-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd740;
}
.app.green-dark .mat-form-field.mat-warn .mat-input-element,
.app.green-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.green-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.green-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.green-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.green-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-list-option:hover, .app.green-dark .mat-list-option:focus,
.app.green-dark .mat-nav-list .mat-list-item:hover,
.app.green-dark .mat-nav-list .mat-list-item:focus,
.app.green-dark .mat-action-list .mat-list-item:hover,
.app.green-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.green-dark .mat-list-single-selected-option, .app.green-dark .mat-list-single-selected-option:hover, .app.green-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-menu-panel {
  background: #424242;
}
.app.green-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.green-dark .mat-menu-item[disabled],
.app.green-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.green-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-menu-item .mat-icon-no-color,
.app.green-dark .mat-menu-submenu-icon {
  color: white;
}
.app.green-dark .mat-menu-item:hover:not([disabled]),
.app.green-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.green-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.green-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.green-dark .mat-paginator {
  background: #424242;
}
.app.green-dark .mat-paginator,
.app.green-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-paginator-decrement,
.app.green-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.green-dark .mat-paginator-first,
.app.green-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.green-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.green-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.green-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.green-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-progress-bar-background {
  fill: #324833;
}
.app.green-dark .mat-progress-bar-buffer {
  background-color: #324833;
}
.app.green-dark .mat-progress-bar-fill::after {
  background-color: #388e3c;
}
.app.green-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645a34;
}
.app.green-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645a34;
}
.app.green-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}
.app.green-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643939;
}
.app.green-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643939;
}
.app.green-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.green-dark .mat-progress-spinner circle, .app.green-dark .mat-spinner circle {
  stroke: #388e3c;
}
.app.green-dark .mat-progress-spinner.mat-accent circle, .app.green-dark .mat-spinner.mat-accent circle {
  stroke: #ffd740;
}
.app.green-dark .mat-progress-spinner.mat-warn circle, .app.green-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.green-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #388e3c;
}
.app.green-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.green-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.green-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #388e3c;
}
.app.green-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd740;
}
.app.green-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.green-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.green-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd740;
}
.app.green-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.green-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.green-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.green-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.green-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.green-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.green-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.green-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.green-dark .mat-select-value {
  color: white;
}
.app.green-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-select-panel {
  background: #424242;
}
.app.green-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #388e3c;
}
.app.green-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd740;
}
.app.green-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.green-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.green-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.green-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.green-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.green-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.green-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.green-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.green-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd740;
}
.app.green-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 215, 64, 0.54);
}
.app.green-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd740;
}
.app.green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #388e3c;
}
.app.green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(56, 142, 60, 0.54);
}
.app.green-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #388e3c;
}
.app.green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.green-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.green-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.green-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.green-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app.green-dark .mat-slider.mat-primary .mat-slider-thumb,
.app.green-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #388e3c;
}
.app.green-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.green-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(56, 142, 60, 0.2);
}
.app.green-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app.green-dark .mat-slider.mat-accent .mat-slider-thumb,
.app.green-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffd740;
}
.app.green-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 215, 64, 0.2);
}
.app.green-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app.green-dark .mat-slider.mat-warn .mat-slider-thumb,
.app.green-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.green-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.green-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.green-dark .mat-slider:hover .mat-slider-track-background,
.app.green-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.green-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.green-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.green-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.green-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.green-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.green-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.green-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.green-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.green-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.green-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.green-dark .mat-step-header.cdk-keyboard-focused, .app.green-dark .mat-step-header.cdk-program-focused, .app.green-dark .mat-step-header:hover:not([aria-disabled]), .app.green-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.green-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.green-dark .mat-step-header:hover {
    background: none;
  }
}
.app.green-dark .mat-step-header .mat-step-label,
.app.green-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.green-dark .mat-step-header .mat-step-icon-selected,
.app.green-dark .mat-step-header .mat-step-icon-state-done,
.app.green-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #388e3c;
  color: white;
}
.app.green-dark .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app.green-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.green-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.green-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app.green-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.green-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.green-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.green-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.green-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.green-dark .mat-stepper-horizontal, .app.green-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.green-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-horizontal-stepper-header::before,
.app.green-dark .mat-horizontal-stepper-header::after,
.app.green-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.green-dark .mat-tab-nav-bar,
.app.green-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.green-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.green-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.green-dark .mat-tab-label, .app.green-dark .mat-tab-link {
  color: white;
}
.app.green-dark .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.green-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.green-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.green-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.green-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.green-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #388e3c;
}
.app.green-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.green-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.green-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd740;
}
.app.green-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.green-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.green-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.green-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.green-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(232, 245, 233, 0.3);
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #388e3c;
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.green-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 229, 127, 0.3);
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffd740;
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.app.green-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.green-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.green-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.green-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.green-dark .mat-toolbar.mat-primary {
  background: #388e3c;
  color: white;
}
.app.green-dark .mat-toolbar.mat-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.green-dark .mat-toolbar .mat-form-field-underline,
.app.green-dark .mat-toolbar .mat-form-field-ripple,
.app.green-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.green-dark .mat-toolbar .mat-form-field-label,
.app.green-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.green-dark .mat-toolbar .mat-select-value,
.app.green-dark .mat-toolbar .mat-select-arrow,
.app.green-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.green-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.green-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.green-dark .mat-tree {
  background: #424242;
}
.app.green-dark .mat-tree-node,
.app.green-dark .mat-nested-tree-node {
  color: white;
}
.app.green-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.green-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.green-dark .bg-primary {
  background: #388e3c;
  color: white;
}
.app.green-dark .bg-accent {
  background: #ffd740;
  color: rgba(0, 0, 0, 0.87);
}
.app.green-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.green-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .primary-color {
  color: #388e3c !important;
}
.app.green-dark .accent-color {
  color: #ffd740 !important;
}
.app.green-dark .warn-color {
  color: #ff5252 !important;
}
.app.green-dark .header {
  background: #424242;
  color: white;
}
.app.green-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.green-dark .content-header h4 {
  color: #e8f5e9;
}
.app.green-dark .favorites-container:after {
  color: #ffd740;
}
.app.green-dark .horizontal-menu {
  background: #424242;
}
.app.green-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.green-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.green-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.green-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.green-dark .menu-item .mat-button .menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.green-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.green-dark .menu-item .mat-button.active-link .menu-icon {
  background: #388e3c;
  color: #fff;
}
.app.green-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.green-dark .breadcrumb a {
  color: #e8f5e9;
}
.app.green-dark .top-toolbar, .app.green-dark .landing-toolbar {
  background: #2e7d32;
  color: #e8f5e9;
}
.app.green-dark .menu-toolbar {
  background: #1b5e20;
  color: #c8e6c9;
}
.app.green-dark .ps__thumb-y {
  background-color: #388e3c;
}
.app.green-dark .testimonial-item, .app.green-dark .pricing-card {
  border-top: 4px solid #ffd740;
}
.app.green-dark .ngx-charts text {
  fill: white;
}
.app.green-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.green-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.green-dark .annular-pagination .ngx-pagination .current {
  background: #388e3c;
  color: white;
}
.app.green-dark .annular-pagination .ngx-pagination a:hover, .app.green-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(56, 142, 60, 0.2);
  color: white;
}
.app.green-dark .cal-day-view .cal-hour-segment:hover,
.app.green-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.green-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.green-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.green-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.green-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.green-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(56, 142, 60, 0.05);
}
.app.green-dark .cal-week-view .cal-header.cal-today,
.app.green-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(56, 142, 60, 0.4);
}
.app.green-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.green-dark .cal-event-action i {
  margin: 4px;
}
.app.green-dark .ql-snow .ql-picker,
.app.green-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.green-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.green-dark .ql-snow .ql-fill {
  fill: white;
}
.app.green-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.green-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.green-dark .ngx-charts text {
  fill: white;
}
.app.pink-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.pink-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-option {
  color: white;
}
.app.pink-dark .mat-option:hover:not(.mat-option-disabled), .app.pink-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.pink-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.pink-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.pink-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c2185b;
}
.app.pink-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #546e7a;
}
.app.pink-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5252;
}
.app.pink-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.pink-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.pink-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.pink-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #c2185b;
}
.app.pink-dark .mat-pseudo-checkbox-checked,
.app.pink-dark .mat-pseudo-checkbox-indeterminate,
.app.pink-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.pink-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #546e7a;
}
.app.pink-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.pink-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5252;
}
.app.pink-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.pink-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.pink-dark .mat-app-background, .app.pink-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.pink-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.pink-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.pink-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.pink-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.pink-dark .mat-badge {
  position: relative;
}
.app.pink-dark .mat-badge.mat-badge {
  overflow: visible;
}
.app.pink-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.pink-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.pink-dark .ng-animate-disabled .mat-badge-content,
.app.pink-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.pink-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.pink-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.pink-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.pink-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.pink-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.pink-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.pink-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.pink-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.pink-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.pink-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.pink-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.pink-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.pink-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.pink-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.pink-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.pink-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.pink-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.pink-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.pink-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.pink-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.pink-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.pink-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.pink-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.pink-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.pink-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.pink-dark .mat-badge-content {
  color: white;
  background: #c2185b;
}
.cdk-high-contrast-active .app.pink-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.pink-dark .mat-badge-accent .mat-badge-content {
  background: #546e7a;
  color: white;
}
.app.pink-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5252;
}
.app.pink-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.pink-dark .mat-button, .app.pink-dark .mat-icon-button, .app.pink-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.pink-dark .mat-button.mat-primary, .app.pink-dark .mat-icon-button.mat-primary, .app.pink-dark .mat-stroked-button.mat-primary {
  color: #c2185b;
}
.app.pink-dark .mat-button.mat-accent, .app.pink-dark .mat-icon-button.mat-accent, .app.pink-dark .mat-stroked-button.mat-accent {
  color: #546e7a;
}
.app.pink-dark .mat-button.mat-warn, .app.pink-dark .mat-icon-button.mat-warn, .app.pink-dark .mat-stroked-button.mat-warn {
  color: #ff5252;
}
.app.pink-dark .mat-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.pink-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.pink-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #c2185b;
}
.app.pink-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.pink-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.pink-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #546e7a;
}
.app.pink-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.pink-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.pink-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5252;
}
.app.pink-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.pink-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.pink-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.pink-dark .mat-button .mat-ripple-element, .app.pink-dark .mat-icon-button .mat-ripple-element, .app.pink-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.pink-dark .mat-button-focus-overlay {
  background: white;
}
.app.pink-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-flat-button, .app.pink-dark .mat-raised-button, .app.pink-dark .mat-fab, .app.pink-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.pink-dark .mat-flat-button.mat-primary, .app.pink-dark .mat-raised-button.mat-primary, .app.pink-dark .mat-fab.mat-primary, .app.pink-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.pink-dark .mat-flat-button.mat-accent, .app.pink-dark .mat-raised-button.mat-accent, .app.pink-dark .mat-fab.mat-accent, .app.pink-dark .mat-mini-fab.mat-accent {
  color: white;
}
.app.pink-dark .mat-flat-button.mat-warn, .app.pink-dark .mat-raised-button.mat-warn, .app.pink-dark .mat-fab.mat-warn, .app.pink-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.pink-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-fab.mat-primary.mat-button-disabled, .app.pink-dark .mat-fab.mat-accent.mat-button-disabled, .app.pink-dark .mat-fab.mat-warn.mat-button-disabled, .app.pink-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-flat-button.mat-primary, .app.pink-dark .mat-raised-button.mat-primary, .app.pink-dark .mat-fab.mat-primary, .app.pink-dark .mat-mini-fab.mat-primary {
  background-color: #c2185b;
}
.app.pink-dark .mat-flat-button.mat-accent, .app.pink-dark .mat-raised-button.mat-accent, .app.pink-dark .mat-fab.mat-accent, .app.pink-dark .mat-mini-fab.mat-accent {
  background-color: #546e7a;
}
.app.pink-dark .mat-flat-button.mat-warn, .app.pink-dark .mat-raised-button.mat-warn, .app.pink-dark .mat-fab.mat-warn, .app.pink-dark .mat-mini-fab.mat-warn {
  background-color: #ff5252;
}
.app.pink-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.pink-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-fab.mat-primary.mat-button-disabled, .app.pink-dark .mat-fab.mat-accent.mat-button-disabled, .app.pink-dark .mat-fab.mat-warn.mat-button-disabled, .app.pink-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.pink-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.pink-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.pink-dark .mat-fab.mat-primary .mat-ripple-element, .app.pink-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.pink-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.pink-dark .mat-fab.mat-accent .mat-ripple-element, .app.pink-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.pink-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.pink-dark .mat-fab.mat-warn .mat-ripple-element, .app.pink-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.pink-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-fab:not([class*=mat-elevation-z]), .app.pink-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.pink-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.pink-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.pink-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.pink-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.pink-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.pink-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.pink-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.app.pink-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.app.pink-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.app.pink-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.pink-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.pink-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.pink-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.pink-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.pink-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.app.pink-dark .mat-card {
  background: #424242;
  color: white;
}
.app.pink-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.pink-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.pink-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.pink-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.pink-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #c2185b;
}
.app.pink-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.pink-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #546e7a;
}
.app.pink-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.pink-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5252;
}
.app.pink-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.pink-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.pink-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.pink-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #c2185b;
}
.app.pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #546e7a;
}
.app.pink-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.pink-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5252;
}
.app.pink-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.pink-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.pink-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #c2185b;
  color: white;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5252;
  color: white;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #546e7a;
  color: white;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-table {
  background: #424242;
}
.app.pink-dark .mat-table thead, .app.pink-dark .mat-table tbody, .app.pink-dark .mat-table tfoot,
.app.pink-dark mat-header-row, .app.pink-dark mat-row, .app.pink-dark mat-footer-row,
.app.pink-dark [mat-header-row], .app.pink-dark [mat-row], .app.pink-dark [mat-footer-row],
.app.pink-dark .mat-table-sticky {
  background: inherit;
}
.app.pink-dark mat-row, .app.pink-dark mat-header-row, .app.pink-dark mat-footer-row,
.app.pink-dark th.mat-header-cell, .app.pink-dark td.mat-cell, .app.pink-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-cell, .app.pink-dark .mat-footer-cell {
  color: white;
}
.app.pink-dark .mat-calendar-arrow {
  fill: white;
}
.app.pink-dark .mat-datepicker-toggle,
.app.pink-dark .mat-datepicker-content .mat-calendar-next-button,
.app.pink-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.pink-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-calendar-table-header,
.app.pink-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-calendar-body-cell-content,
.app.pink-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.pink-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.pink-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-calendar-body-in-range::before {
  background: rgba(194, 24, 91, 0.2);
}
.app.pink-dark .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink-dark .mat-calendar-body-comparison-bridge-start::before,
.app.pink-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-calendar-body-comparison-bridge-end::before,
.app.pink-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(194, 24, 91, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink-dark .mat-calendar-body-selected {
  background-color: #c2185b;
  color: white;
}
.app.pink-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.pink-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(194, 24, 91, 0.3);
}
@media (hover: hover) {
  .app.pink-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(194, 24, 91, 0.3);
  }
}
.app.pink-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(84, 110, 122, 0.2);
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.pink-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.pink-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(84, 110, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #546e7a;
  color: white;
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(84, 110, 122, 0.4);
}
.app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(84, 110, 122, 0.3);
}
@media (hover: hover) {
  .app.pink-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(84, 110, 122, 0.3);
  }
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 82, 82, 0.2);
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.pink-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.pink-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 82, 82, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5252;
  color: white;
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 82, 82, 0.4);
}
.app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.pink-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.pink-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 82, 82, 0.3);
}
@media (hover: hover) {
  .app.pink-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 82, 82, 0.3);
  }
}
.app.pink-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-datepicker-toggle-active {
  color: #c2185b;
}
.app.pink-dark .mat-datepicker-toggle-active.mat-accent {
  color: #546e7a;
}
.app.pink-dark .mat-datepicker-toggle-active.mat-warn {
  color: #ff5252;
}
.app.pink-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.pink-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.pink-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.pink-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.pink-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.pink-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.pink-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.pink-dark .mat-expansion-panel-header-description,
.app.pink-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.pink-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.pink-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #c2185b;
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #546e7a;
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5252;
}
.app.pink-dark .mat-focused .mat-form-field-required-marker {
  color: #546e7a;
}
.app.pink-dark .mat-form-field-ripple {
  background-color: white;
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #c2185b;
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #546e7a;
}
.app.pink-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5252;
}
.app.pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #c2185b;
}
.app.pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #546e7a;
}
.app.pink-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5252;
}
.app.pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5252;
}
.app.pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5252;
}
.app.pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.pink-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5252;
}
.app.pink-dark .mat-error {
  color: #ff5252;
}
.app.pink-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.pink-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.pink-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.pink-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.pink-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.pink-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #c2185b;
}
.app.pink-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #546e7a;
}
.app.pink-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.pink-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5252;
}
.app.pink-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.pink-dark .mat-icon.mat-primary {
  color: #c2185b;
}
.app.pink-dark .mat-icon.mat-accent {
  color: #546e7a;
}
.app.pink-dark .mat-icon.mat-warn {
  color: #ff5252;
}
.app.pink-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-input-element:disabled,
.app.pink-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-input-element {
  caret-color: #c2185b;
}
.app.pink-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app.pink-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.pink-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #546e7a;
}
.app.pink-dark .mat-form-field.mat-warn .mat-input-element,
.app.pink-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5252;
}
.app.pink-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5252;
}
.app.pink-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.pink-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.pink-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-list-option:hover, .app.pink-dark .mat-list-option:focus,
.app.pink-dark .mat-nav-list .mat-list-item:hover,
.app.pink-dark .mat-nav-list .mat-list-item:focus,
.app.pink-dark .mat-action-list .mat-list-item:hover,
.app.pink-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.pink-dark .mat-list-single-selected-option, .app.pink-dark .mat-list-single-selected-option:hover, .app.pink-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-menu-panel {
  background: #424242;
}
.app.pink-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.pink-dark .mat-menu-item[disabled],
.app.pink-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.pink-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-menu-item .mat-icon-no-color,
.app.pink-dark .mat-menu-submenu-icon {
  color: white;
}
.app.pink-dark .mat-menu-item:hover:not([disabled]),
.app.pink-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.pink-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.pink-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.pink-dark .mat-paginator {
  background: #424242;
}
.app.pink-dark .mat-paginator,
.app.pink-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-paginator-decrement,
.app.pink-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.pink-dark .mat-paginator-first,
.app.pink-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.pink-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.pink-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.pink-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.pink-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-progress-bar-background {
  fill: #552a3b;
}
.app.pink-dark .mat-progress-bar-buffer {
  background-color: #552a3b;
}
.app.pink-dark .mat-progress-bar-fill::after {
  background-color: #c2185b;
}
.app.pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #394043;
}
.app.pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #394043;
}
.app.pink-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #546e7a;
}
.app.pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643939;
}
.app.pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643939;
}
.app.pink-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5252;
}
.app.pink-dark .mat-progress-spinner circle, .app.pink-dark .mat-spinner circle {
  stroke: #c2185b;
}
.app.pink-dark .mat-progress-spinner.mat-accent circle, .app.pink-dark .mat-spinner.mat-accent circle {
  stroke: #546e7a;
}
.app.pink-dark .mat-progress-spinner.mat-warn circle, .app.pink-dark .mat-spinner.mat-warn circle {
  stroke: #ff5252;
}
.app.pink-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c2185b;
}
.app.pink-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.pink-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.pink-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #c2185b;
}
.app.pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #546e7a;
}
.app.pink-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.pink-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.pink-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #546e7a;
}
.app.pink-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5252;
}
.app.pink-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.pink-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.pink-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.pink-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5252;
}
.app.pink-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.pink-dark .mat-select-value {
  color: white;
}
.app.pink-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-select-panel {
  background: #424242;
}
.app.pink-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #c2185b;
}
.app.pink-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #546e7a;
}
.app.pink-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5252;
}
.app.pink-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5252;
}
.app.pink-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.pink-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.pink-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.pink-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.pink-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.pink-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #546e7a;
}
.app.pink-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(84, 110, 122, 0.54);
}
.app.pink-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #546e7a;
}
.app.pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #c2185b;
}
.app.pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(194, 24, 91, 0.54);
}
.app.pink-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #c2185b;
}
.app.pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5252;
}
.app.pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 82, 82, 0.54);
}
.app.pink-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5252;
}
.app.pink-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.pink-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.pink-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app.pink-dark .mat-slider.mat-primary .mat-slider-thumb,
.app.pink-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #c2185b;
}
.app.pink-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.pink-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(194, 24, 91, 0.2);
}
.app.pink-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app.pink-dark .mat-slider.mat-accent .mat-slider-thumb,
.app.pink-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #546e7a;
}
.app.pink-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.pink-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(84, 110, 122, 0.2);
}
.app.pink-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app.pink-dark .mat-slider.mat-warn .mat-slider-thumb,
.app.pink-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5252;
}
.app.pink-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.pink-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 82, 82, 0.2);
}
.app.pink-dark .mat-slider:hover .mat-slider-track-background,
.app.pink-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.pink-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.pink-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.pink-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.pink-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.pink-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.pink-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.pink-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.pink-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.pink-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.pink-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.pink-dark .mat-step-header.cdk-keyboard-focused, .app.pink-dark .mat-step-header.cdk-program-focused, .app.pink-dark .mat-step-header:hover:not([aria-disabled]), .app.pink-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.pink-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.pink-dark .mat-step-header:hover {
    background: none;
  }
}
.app.pink-dark .mat-step-header .mat-step-label,
.app.pink-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.pink-dark .mat-step-header .mat-step-icon-selected,
.app.pink-dark .mat-step-header .mat-step-icon-state-done,
.app.pink-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #c2185b;
  color: white;
}
.app.pink-dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.pink-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app.pink-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.pink-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #546e7a;
  color: white;
}
.app.pink-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.pink-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app.pink-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.pink-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5252;
  color: white;
}
.app.pink-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5252;
}
.app.pink-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.pink-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5252;
}
.app.pink-dark .mat-stepper-horizontal, .app.pink-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.pink-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-horizontal-stepper-header::before,
.app.pink-dark .mat-horizontal-stepper-header::after,
.app.pink-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.pink-dark .mat-tab-nav-bar,
.app.pink-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.pink-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.pink-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.pink-dark .mat-tab-label, .app.pink-dark .mat-tab-link {
  color: white;
}
.app.pink-dark .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.pink-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.pink-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.pink-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.pink-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.pink-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #c2185b;
}
.app.pink-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(144, 164, 174, 0.3);
}
.app.pink-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #546e7a;
}
.app.pink-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.pink-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5252;
}
.app.pink-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.pink-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.pink-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 228, 236, 0.3);
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #c2185b;
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(144, 164, 174, 0.3);
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #546e7a;
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.pink-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5252;
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.pink-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.pink-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.pink-dark .mat-toolbar.mat-primary {
  background: #c2185b;
  color: white;
}
.app.pink-dark .mat-toolbar.mat-accent {
  background: #546e7a;
  color: white;
}
.app.pink-dark .mat-toolbar.mat-warn {
  background: #ff5252;
  color: white;
}
.app.pink-dark .mat-toolbar .mat-form-field-underline,
.app.pink-dark .mat-toolbar .mat-form-field-ripple,
.app.pink-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.pink-dark .mat-toolbar .mat-form-field-label,
.app.pink-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.pink-dark .mat-toolbar .mat-select-value,
.app.pink-dark .mat-toolbar .mat-select-arrow,
.app.pink-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.pink-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.pink-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.pink-dark .mat-tree {
  background: #424242;
}
.app.pink-dark .mat-tree-node,
.app.pink-dark .mat-nested-tree-node {
  color: white;
}
.app.pink-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.pink-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.pink-dark .bg-primary {
  background: #c2185b;
  color: white;
}
.app.pink-dark .bg-accent {
  background: #546e7a;
  color: white;
}
.app.pink-dark .bg-warn {
  background: #ff5252;
  color: white;
}
.app.pink-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .primary-color {
  color: #c2185b !important;
}
.app.pink-dark .accent-color {
  color: #546e7a !important;
}
.app.pink-dark .warn-color {
  color: #ff5252 !important;
}
.app.pink-dark .header {
  background: #424242;
  color: white;
}
.app.pink-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .content-header h4 {
  color: #fce4ec;
}
.app.pink-dark .favorites-container:after {
  color: #546e7a;
}
.app.pink-dark .horizontal-menu {
  background: #424242;
}
.app.pink-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.pink-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.pink-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #c2185b;
  color: #fff;
}
.app.pink-dark .menu-item .mat-button .menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.pink-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.pink-dark .menu-item .mat-button.active-link .menu-icon {
  background: #c2185b;
  color: #fff;
}
.app.pink-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.pink-dark .breadcrumb a {
  color: #fce4ec;
}
.app.pink-dark .top-toolbar, .app.pink-dark .landing-toolbar {
  background: #ad1457;
  color: #fce4ec;
}
.app.pink-dark .menu-toolbar {
  background: #880e4f;
  color: #f8bbd0;
}
.app.pink-dark .ps__thumb-y {
  background-color: #c2185b;
}
.app.pink-dark .testimonial-item, .app.pink-dark .pricing-card {
  border-top: 4px solid #546e7a;
}
.app.pink-dark .ngx-charts text {
  fill: white;
}
.app.pink-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.pink-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.pink-dark .annular-pagination .ngx-pagination .current {
  background: #c2185b;
  color: white;
}
.app.pink-dark .annular-pagination .ngx-pagination a:hover, .app.pink-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(194, 24, 91, 0.2);
  color: white;
}
.app.pink-dark .cal-day-view .cal-hour-segment:hover,
.app.pink-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.pink-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.pink-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.pink-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.pink-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.pink-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(194, 24, 91, 0.05);
}
.app.pink-dark .cal-week-view .cal-header.cal-today,
.app.pink-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(194, 24, 91, 0.4);
}
.app.pink-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.pink-dark .cal-event-action i {
  margin: 4px;
}
.app.pink-dark .ql-snow .ql-picker,
.app.pink-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.pink-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.pink-dark .ql-snow .ql-fill {
  fill: white;
}
.app.pink-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.pink-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.pink-dark .ngx-charts text {
  fill: white;
}
.app.gray-dark {
  /* ngx-charts */
  /* ngx-pagination */
  /* angular-calendar */
  /* ngx-quill */
  /* ngx-charts */
}
.app.gray-dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-option {
  color: white;
}
.app.gray-dark .mat-option:hover:not(.mat-option-disabled), .app.gray-dark .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.gray-dark .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.app.gray-dark .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.app.gray-dark .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #455a64;
}
.app.gray-dark .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.app.gray-dark .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.app.gray-dark .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-pseudo-checkbox::after {
  color: #303030;
}
.app.gray-dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.app.gray-dark .mat-primary .mat-pseudo-checkbox-checked,
.app.gray-dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #455a64;
}
.app.gray-dark .mat-pseudo-checkbox-checked,
.app.gray-dark .mat-pseudo-checkbox-indeterminate,
.app.gray-dark .mat-accent .mat-pseudo-checkbox-checked,
.app.gray-dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.app.gray-dark .mat-warn .mat-pseudo-checkbox-checked,
.app.gray-dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.app.gray-dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.app.gray-dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.app.gray-dark .mat-app-background, .app.gray-dark.mat-app-background {
  background-color: #303030;
  color: white;
}
.app.gray-dark .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.app.gray-dark .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.app.gray-dark .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.app.gray-dark .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.app.gray-dark .mat-badge {
  position: relative;
}
.app.gray-dark .mat-badge.mat-badge {
  overflow: visible;
}
.app.gray-dark .mat-badge-hidden .mat-badge-content {
  display: none;
}
.app.gray-dark .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.app.gray-dark .ng-animate-disabled .mat-badge-content,
.app.gray-dark .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.app.gray-dark .mat-badge-content.mat-badge-active {
  transform: none;
}
.app.gray-dark .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.app.gray-dark .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.app.gray-dark .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.app.gray-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .app.gray-dark .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.app.gray-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .app.gray-dark .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.app.gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .app.gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.app.gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .app.gray-dark .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.app.gray-dark .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.app.gray-dark .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.app.gray-dark .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.app.gray-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .app.gray-dark .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.app.gray-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .app.gray-dark .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.app.gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .app.gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.app.gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .app.gray-dark .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.app.gray-dark .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.app.gray-dark .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.app.gray-dark .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.app.gray-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .app.gray-dark .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.app.gray-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .app.gray-dark .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.app.gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .app.gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.app.gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .app.gray-dark .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.app.gray-dark .mat-badge-content {
  color: white;
  background: #455a64;
}
.cdk-high-contrast-active .app.gray-dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.app.gray-dark .mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.app.gray-dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.app.gray-dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.gray-dark .mat-button, .app.gray-dark .mat-icon-button, .app.gray-dark .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.app.gray-dark .mat-button.mat-primary, .app.gray-dark .mat-icon-button.mat-primary, .app.gray-dark .mat-stroked-button.mat-primary {
  color: #455a64;
}
.app.gray-dark .mat-button.mat-accent, .app.gray-dark .mat-icon-button.mat-accent, .app.gray-dark .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.app.gray-dark .mat-button.mat-warn, .app.gray-dark .mat-icon-button.mat-warn, .app.gray-dark .mat-stroked-button.mat-warn {
  color: #f44336;
}
.app.gray-dark .mat-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-icon-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-icon-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-icon-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-icon-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-stroked-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-stroked-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-stroked-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-button.mat-primary .mat-button-focus-overlay, .app.gray-dark .mat-icon-button.mat-primary .mat-button-focus-overlay, .app.gray-dark .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #455a64;
}
.app.gray-dark .mat-button.mat-accent .mat-button-focus-overlay, .app.gray-dark .mat-icon-button.mat-accent .mat-button-focus-overlay, .app.gray-dark .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.app.gray-dark .mat-button.mat-warn .mat-button-focus-overlay, .app.gray-dark .mat-icon-button.mat-warn .mat-button-focus-overlay, .app.gray-dark .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.app.gray-dark .mat-button.mat-button-disabled .mat-button-focus-overlay, .app.gray-dark .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .app.gray-dark .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.app.gray-dark .mat-button .mat-ripple-element, .app.gray-dark .mat-icon-button .mat-ripple-element, .app.gray-dark .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.app.gray-dark .mat-button-focus-overlay {
  background: white;
}
.app.gray-dark .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-flat-button, .app.gray-dark .mat-raised-button, .app.gray-dark .mat-fab, .app.gray-dark .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.app.gray-dark .mat-flat-button.mat-primary, .app.gray-dark .mat-raised-button.mat-primary, .app.gray-dark .mat-fab.mat-primary, .app.gray-dark .mat-mini-fab.mat-primary {
  color: white;
}
.app.gray-dark .mat-flat-button.mat-accent, .app.gray-dark .mat-raised-button.mat-accent, .app.gray-dark .mat-fab.mat-accent, .app.gray-dark .mat-mini-fab.mat-accent {
  color: white;
}
.app.gray-dark .mat-flat-button.mat-warn, .app.gray-dark .mat-raised-button.mat-warn, .app.gray-dark .mat-fab.mat-warn, .app.gray-dark .mat-mini-fab.mat-warn {
  color: white;
}
.app.gray-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-fab.mat-primary.mat-button-disabled, .app.gray-dark .mat-fab.mat-accent.mat-button-disabled, .app.gray-dark .mat-fab.mat-warn.mat-button-disabled, .app.gray-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-flat-button.mat-primary, .app.gray-dark .mat-raised-button.mat-primary, .app.gray-dark .mat-fab.mat-primary, .app.gray-dark .mat-mini-fab.mat-primary {
  background-color: #455a64;
}
.app.gray-dark .mat-flat-button.mat-accent, .app.gray-dark .mat-raised-button.mat-accent, .app.gray-dark .mat-fab.mat-accent, .app.gray-dark .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.app.gray-dark .mat-flat-button.mat-warn, .app.gray-dark .mat-raised-button.mat-warn, .app.gray-dark .mat-fab.mat-warn, .app.gray-dark .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.app.gray-dark .mat-flat-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-flat-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-primary.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-accent.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-warn.mat-button-disabled, .app.gray-dark .mat-raised-button.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-fab.mat-primary.mat-button-disabled, .app.gray-dark .mat-fab.mat-accent.mat-button-disabled, .app.gray-dark .mat-fab.mat-warn.mat-button-disabled, .app.gray-dark .mat-fab.mat-button-disabled.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-primary.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-accent.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-warn.mat-button-disabled, .app.gray-dark .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-flat-button.mat-primary .mat-ripple-element, .app.gray-dark .mat-raised-button.mat-primary .mat-ripple-element, .app.gray-dark .mat-fab.mat-primary .mat-ripple-element, .app.gray-dark .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-flat-button.mat-accent .mat-ripple-element, .app.gray-dark .mat-raised-button.mat-accent .mat-ripple-element, .app.gray-dark .mat-fab.mat-accent .mat-ripple-element, .app.gray-dark .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-flat-button.mat-warn .mat-ripple-element, .app.gray-dark .mat-raised-button.mat-warn .mat-ripple-element, .app.gray-dark .mat-fab.mat-warn .mat-ripple-element, .app.gray-dark .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-stroked-button:not([class*=mat-elevation-z]), .app.gray-dark .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-fab:not([class*=mat-elevation-z]), .app.gray-dark .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .app.gray-dark .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .app.gray-dark .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.app.gray-dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.app.gray-dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.app.gray-dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.app.gray-dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.app.gray-dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.app.gray-dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.app.gray-dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.app.gray-dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.app.gray-dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.app.gray-dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.app.gray-dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.app.gray-dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.app.gray-dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.app.gray-dark .mat-card {
  background: #424242;
  color: white;
}
.app.gray-dark .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-checkbox-checkmark {
  fill: #303030;
}
.app.gray-dark .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.app.gray-dark .mat-checkbox-mixedmark {
  background-color: #303030;
}
.app.gray-dark .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .app.gray-dark .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #455a64;
}
.app.gray-dark .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .app.gray-dark .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.app.gray-dark .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .app.gray-dark .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.app.gray-dark .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .app.gray-dark .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.app.gray-dark .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.app.gray-dark .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.app.gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.app.gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #455a64;
}
.app.gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.app.gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.app.gray-dark .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.app.gray-dark .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.app.gray-dark .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.app.gray-dark .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.app.gray-dark .mat-chip.mat-standard-chip::after {
  background: white;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #455a64;
  color: white;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-table {
  background: #424242;
}
.app.gray-dark .mat-table thead, .app.gray-dark .mat-table tbody, .app.gray-dark .mat-table tfoot,
.app.gray-dark mat-header-row, .app.gray-dark mat-row, .app.gray-dark mat-footer-row,
.app.gray-dark [mat-header-row], .app.gray-dark [mat-row], .app.gray-dark [mat-footer-row],
.app.gray-dark .mat-table-sticky {
  background: inherit;
}
.app.gray-dark mat-row, .app.gray-dark mat-header-row, .app.gray-dark mat-footer-row,
.app.gray-dark th.mat-header-cell, .app.gray-dark td.mat-cell, .app.gray-dark td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-cell, .app.gray-dark .mat-footer-cell {
  color: white;
}
.app.gray-dark .mat-calendar-arrow {
  fill: white;
}
.app.gray-dark .mat-datepicker-toggle,
.app.gray-dark .mat-datepicker-content .mat-calendar-next-button,
.app.gray-dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.app.gray-dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-calendar-table-header,
.app.gray-dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-calendar-body-cell-content,
.app.gray-dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.app.gray-dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.app.gray-dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-calendar-body-in-range::before {
  background: rgba(69, 90, 100, 0.2);
}
.app.gray-dark .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-dark .mat-calendar-body-comparison-bridge-start::before,
.app.gray-dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-calendar-body-comparison-bridge-end::before,
.app.gray-dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(69, 90, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-dark .mat-calendar-body-selected {
  background-color: #455a64;
  color: white;
}
.app.gray-dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.gray-dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(69, 90, 100, 0.3);
}
@media (hover: hover) {
  .app.gray-dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(69, 90, 100, 0.3);
  }
}
.app.gray-dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.app.gray-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.app.gray-dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .app.gray-dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.app.gray-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.app.gray-dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.app.gray-dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.app.gray-dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .app.gray-dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.app.gray-dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-datepicker-toggle-active {
  color: #455a64;
}
.app.gray-dark .mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.app.gray-dark .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.app.gray-dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.app.gray-dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
.app.gray-dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .app.gray-dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .app.gray-dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .app.gray-dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.app.gray-dark .mat-expansion-panel-header-title {
  color: white;
}
.app.gray-dark .mat-expansion-panel-header-description,
.app.gray-dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.app.gray-dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.app.gray-dark .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-label {
  color: #455a64;
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.app.gray-dark .mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.app.gray-dark .mat-form-field-ripple {
  background-color: white;
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #455a64;
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.app.gray-dark .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.app.gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #455a64;
}
.app.gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.app.gray-dark .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.app.gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.app.gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.app.gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.app.gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.app.gray-dark .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.app.gray-dark .mat-error {
  color: #f44336;
}
.app.gray-dark .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.gray-dark .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.app.gray-dark .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.app.gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.app.gray-dark .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.app.gray-dark .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.app.gray-dark .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #455a64;
}
.app.gray-dark .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.app.gray-dark .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.app.gray-dark .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.app.gray-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.app.gray-dark .mat-icon.mat-primary {
  color: #455a64;
}
.app.gray-dark .mat-icon.mat-accent {
  color: #ff4081;
}
.app.gray-dark .mat-icon.mat-warn {
  color: #f44336;
}
.app.gray-dark .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-input-element:disabled,
.app.gray-dark .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-input-element {
  caret-color: #455a64;
}
.app.gray-dark .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.app.gray-dark .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.app.gray-dark .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.app.gray-dark .mat-form-field.mat-warn .mat-input-element,
.app.gray-dark .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.app.gray-dark .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.app.gray-dark .mat-list-base .mat-list-item {
  color: white;
}
.app.gray-dark .mat-list-base .mat-list-option {
  color: white;
}
.app.gray-dark .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-list-option:hover, .app.gray-dark .mat-list-option:focus,
.app.gray-dark .mat-nav-list .mat-list-item:hover,
.app.gray-dark .mat-nav-list .mat-list-item:focus,
.app.gray-dark .mat-action-list .mat-list-item:hover,
.app.gray-dark .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.app.gray-dark .mat-list-single-selected-option, .app.gray-dark .mat-list-single-selected-option:hover, .app.gray-dark .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-menu-panel {
  background: #424242;
}
.app.gray-dark .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-menu-item {
  background: transparent;
  color: white;
}
.app.gray-dark .mat-menu-item[disabled],
.app.gray-dark .mat-menu-item[disabled] .mat-menu-submenu-icon,
.app.gray-dark .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-menu-item .mat-icon-no-color,
.app.gray-dark .mat-menu-submenu-icon {
  color: white;
}
.app.gray-dark .mat-menu-item:hover:not([disabled]),
.app.gray-dark .mat-menu-item.cdk-program-focused:not([disabled]),
.app.gray-dark .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.app.gray-dark .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.app.gray-dark .mat-paginator {
  background: #424242;
}
.app.gray-dark .mat-paginator,
.app.gray-dark .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-paginator-decrement,
.app.gray-dark .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.app.gray-dark .mat-paginator-first,
.app.gray-dark .mat-paginator-last {
  border-top: 2px solid white;
}
.app.gray-dark .mat-icon-button[disabled] .mat-paginator-decrement,
.app.gray-dark .mat-icon-button[disabled] .mat-paginator-increment,
.app.gray-dark .mat-icon-button[disabled] .mat-paginator-first,
.app.gray-dark .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-progress-bar-background {
  fill: #353b3d;
}
.app.gray-dark .mat-progress-bar-buffer {
  background-color: #353b3d;
}
.app.gray-dark .mat-progress-bar-fill::after {
  background-color: #455a64;
}
.app.gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #643444;
}
.app.gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #643444;
}
.app.gray-dark .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.app.gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #613532;
}
.app.gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #613532;
}
.app.gray-dark .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.app.gray-dark .mat-progress-spinner circle, .app.gray-dark .mat-spinner circle {
  stroke: #455a64;
}
.app.gray-dark .mat-progress-spinner.mat-accent circle, .app.gray-dark .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.app.gray-dark .mat-progress-spinner.mat-warn circle, .app.gray-dark .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.app.gray-dark .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #455a64;
}
.app.gray-dark .mat-radio-button.mat-primary .mat-radio-inner-circle,
.app.gray-dark .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-dark .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-dark .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #455a64;
}
.app.gray-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.app.gray-dark .mat-radio-button.mat-accent .mat-radio-inner-circle,
.app.gray-dark .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-dark .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-dark .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.app.gray-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.app.gray-dark .mat-radio-button.mat-warn .mat-radio-inner-circle,
.app.gray-dark .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .app.gray-dark .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .app.gray-dark .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.app.gray-dark .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.app.gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.app.gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.app.gray-dark .mat-select-value {
  color: white;
}
.app.gray-dark .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-select-panel {
  background: #424242;
}
.app.gray-dark .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #455a64;
}
.app.gray-dark .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.app.gray-dark .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.app.gray-dark .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.app.gray-dark .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.app.gray-dark .mat-drawer {
  background-color: #424242;
  color: white;
}
.app.gray-dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.app.gray-dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.gray-dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.app.gray-dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.app.gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.app.gray-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.app.gray-dark .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.app.gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #455a64;
}
.app.gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(69, 90, 100, 0.54);
}
.app.gray-dark .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #455a64;
}
.app.gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.app.gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.app.gray-dark .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.app.gray-dark .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.app.gray-dark .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.app.gray-dark .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-primary .mat-slider-track-fill,
.app.gray-dark .mat-slider.mat-primary .mat-slider-thumb,
.app.gray-dark .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #455a64;
}
.app.gray-dark .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-dark .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(69, 90, 100, 0.2);
}
.app.gray-dark .mat-slider.mat-accent .mat-slider-track-fill,
.app.gray-dark .mat-slider.mat-accent .mat-slider-thumb,
.app.gray-dark .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.app.gray-dark .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-dark .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.app.gray-dark .mat-slider.mat-warn .mat-slider-track-fill,
.app.gray-dark .mat-slider.mat-warn .mat-slider-thumb,
.app.gray-dark .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.app.gray-dark .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.app.gray-dark .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.app.gray-dark .mat-slider:hover .mat-slider-track-background,
.app.gray-dark .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-slider-disabled .mat-slider-track-background,
.app.gray-dark .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.app.gray-dark .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.app.gray-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.app.gray-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.app.gray-dark .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.app.gray-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .app.gray-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .app.gray-dark .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.app.gray-dark .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.gray-dark .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.app.gray-dark .mat-step-header.cdk-keyboard-focused, .app.gray-dark .mat-step-header.cdk-program-focused, .app.gray-dark .mat-step-header:hover:not([aria-disabled]), .app.gray-dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.app.gray-dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .app.gray-dark .mat-step-header:hover {
    background: none;
  }
}
.app.gray-dark .mat-step-header .mat-step-label,
.app.gray-dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.app.gray-dark .mat-step-header .mat-step-icon-selected,
.app.gray-dark .mat-step-header .mat-step-icon-state-done,
.app.gray-dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #455a64;
  color: white;
}
.app.gray-dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.app.gray-dark .mat-step-header.mat-accent .mat-step-icon-selected,
.app.gray-dark .mat-step-header.mat-accent .mat-step-icon-state-done,
.app.gray-dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.app.gray-dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.app.gray-dark .mat-step-header.mat-warn .mat-step-icon-selected,
.app.gray-dark .mat-step-header.mat-warn .mat-step-icon-state-done,
.app.gray-dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.app.gray-dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.app.gray-dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.app.gray-dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.app.gray-dark .mat-stepper-horizontal, .app.gray-dark .mat-stepper-vertical {
  background-color: #424242;
}
.app.gray-dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-horizontal-stepper-header::before,
.app.gray-dark .mat-horizontal-stepper-header::after,
.app.gray-dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
.app.gray-dark .mat-tab-nav-bar,
.app.gray-dark .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.app.gray-dark .mat-tab-group-inverted-header .mat-tab-nav-bar,
.app.gray-dark .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.app.gray-dark .mat-tab-label, .app.gray-dark .mat-tab-link {
  color: white;
}
.app.gray-dark .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-tab-header-pagination-chevron {
  border-color: white;
}
.app.gray-dark .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.app.gray-dark .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.app.gray-dark .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.app.gray-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.gray-dark .mat-tab-group.mat-primary .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #455a64;
}
.app.gray-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.gray-dark .mat-tab-group.mat-accent .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.app.gray-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.gray-dark .mat-tab-group.mat-warn .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.app.gray-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .app.gray-dark .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.app.gray-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(236, 239, 241, 0.3);
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header, .app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container, .app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #455a64;
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header, .app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container, .app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .app.gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.app.gray-dark .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header, .app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container, .app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.app.gray-dark .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.app.gray-dark .mat-toolbar {
  background: #212121;
  color: white;
}
.app.gray-dark .mat-toolbar.mat-primary {
  background: #455a64;
  color: white;
}
.app.gray-dark .mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.app.gray-dark .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.app.gray-dark .mat-toolbar .mat-form-field-underline,
.app.gray-dark .mat-toolbar .mat-form-field-ripple,
.app.gray-dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.app.gray-dark .mat-toolbar .mat-form-field-label,
.app.gray-dark .mat-toolbar .mat-focused .mat-form-field-label,
.app.gray-dark .mat-toolbar .mat-select-value,
.app.gray-dark .mat-toolbar .mat-select-arrow,
.app.gray-dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.app.gray-dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.app.gray-dark .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.app.gray-dark .mat-tree {
  background: #424242;
}
.app.gray-dark .mat-tree-node,
.app.gray-dark .mat-nested-tree-node {
  color: white;
}
.app.gray-dark .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.app.gray-dark .mat-simple-snackbar-action {
  color: inherit;
}
.app.gray-dark .bg-primary {
  background: #455a64;
  color: white;
}
.app.gray-dark .bg-accent {
  background: #ff4081;
  color: white;
}
.app.gray-dark .bg-warn {
  background: #f44336;
  color: white;
}
.app.gray-dark .secondary-text-color {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .primary-color {
  color: #455a64 !important;
}
.app.gray-dark .accent-color {
  color: #ff4081 !important;
}
.app.gray-dark .warn-color {
  color: #f44336 !important;
}
.app.gray-dark .header {
  background: #424242;
  color: white;
}
.app.gray-dark .header .info-content .top-contact-form {
  border-color: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .content-header h4 {
  color: #eceff1;
}
.app.gray-dark .favorites-container:after {
  color: #ff4081;
}
.app.gray-dark .horizontal-menu {
  background: #424242;
}
.app.gray-dark .horizontal-menu-item .horizontal-sub-menu {
  background: #424242;
}
.app.gray-dark .horizontal-menu-item .mat-button {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .horizontal-menu-item .mat-button .horizontal-menu-title {
  color: white;
}
.app.gray-dark .horizontal-menu-item .mat-button.parent-item .horizontal-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .horizontal-menu-item .mat-button.parent-item.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .horizontal-menu-item .mat-button.parent-item.active-link .horizontal-menu-icon {
  background: #455a64;
  color: #fff;
}
.app.gray-dark .menu-item .mat-button .menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .menu-item .mat-button .menu-title {
  color: white;
}
.app.gray-dark .menu-item .mat-button.active-link {
  background: rgba(255, 255, 255, 0.12);
}
.app.gray-dark .menu-item .mat-button.active-link .menu-icon {
  background: #455a64;
  color: #fff;
}
.app.gray-dark .menu-expand-icon {
  color: rgba(255, 255, 255, 0.7);
}
.app.gray-dark .breadcrumb a {
  color: #eceff1;
}
.app.gray-dark .top-toolbar, .app.gray-dark .landing-toolbar {
  background: #37474f;
  color: #eceff1;
}
.app.gray-dark .menu-toolbar {
  background: #263238;
  color: #cfd8dc;
}
.app.gray-dark .ps__thumb-y {
  background-color: #455a64;
}
.app.gray-dark .testimonial-item, .app.gray-dark .pricing-card {
  border-top: 4px solid #ff4081;
}
.app.gray-dark .ngx-charts text {
  fill: white;
}
.app.gray-dark [class*=gradient-] .ngx-charts text {
  fill: #fff;
}
.app.gray-dark .annular-pagination .ngx-pagination {
  margin-bottom: 0;
  padding: 4px;
}
.app.gray-dark .annular-pagination .ngx-pagination .current {
  background: #455a64;
  color: white;
}
.app.gray-dark .annular-pagination .ngx-pagination a:hover, .app.gray-dark .annular-pagination .ngx-pagination button:hover {
  background: rgba(69, 90, 100, 0.2);
  color: white;
}
.app.gray-dark .cal-day-view .cal-hour-segment:hover,
.app.gray-dark .cal-day-view .cal-drag-over .cal-hour-segment,
.app.gray-dark .cal-week-view .cal-day-headers .cal-header:hover,
.app.gray-dark .cal-week-view .cal-day-headers .cal-drag-over,
.app.gray-dark .cal-month-view .cal-cell-row .cal-cell:hover,
.app.gray-dark .cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.gray-dark .cal-month-view .cal-cell-row:hover {
  background-color: rgba(69, 90, 100, 0.05);
}
.app.gray-dark .cal-week-view .cal-header.cal-today,
.app.gray-dark .cal-month-view .cal-day-cell.cal-today {
  background-color: rgba(69, 90, 100, 0.4);
}
.app.gray-dark .cal-day-view .cal-hour:nth-child(odd) {
  background-color: inherit;
}
.app.gray-dark .cal-event-action i {
  margin: 4px;
}
.app.gray-dark .ql-snow .ql-picker,
.app.gray-dark .ql-editor.ql-blank::before {
  color: white;
}
.app.gray-dark .ql-snow .ql-stroke {
  stroke: white;
}
.app.gray-dark .ql-snow .ql-fill {
  fill: white;
}
.app.gray-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.app.gray-dark .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #212121;
}
.app.gray-dark .ngx-charts text {
  fill: white;
}

.gradient-purple {
  background: #7873f5 !important;
  background: -webkit-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: -o-linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  background: linear-gradient(50deg, #ff6ec4, #7873f5) !important;
  color: #fff !important;
}

.gradient-indigo {
  background: #303f9f !important;
  background: -webkit-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: -o-linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  background: linear-gradient(50deg, #2ec6ff, #303f9f) !important;
  color: #fff !important;
}

.gradient-blue {
  background: #2196F3 !important;
  background: -webkit-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: -o-linear-gradient(50deg, #37ffb5, #2196F3) !important;
  background: linear-gradient(50deg, #37ffb5, #2196F3) !important;
  color: #fff !important;
}

.gradient-orange {
  background: #FF7043 !important;
  background: -webkit-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: -o-linear-gradient(50deg, #ffd86f, #FF7043) !important;
  background: linear-gradient(50deg, #ffd86f, #FF7043) !important;
  color: #fff !important;
}

.gradient-green {
  background: #2E7D32 !important;
  background: -webkit-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: -o-linear-gradient(50deg, #C5D429, #2E7D32) !important;
  background: linear-gradient(50deg, #C5D429, #2E7D32) !important;
  color: #fff !important;
}

.gradient-pink {
  background: #E91E63 !important;
  background: -webkit-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: -o-linear-gradient(50deg, #F59E99, #E91E63) !important;
  background: linear-gradient(50deg, #F59E99, #E91E63) !important;
  color: #fff !important;
}

.gradient-red {
  background: #D50000 !important;
  background: -webkit-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: -o-linear-gradient(50deg, #FF9E80, #D50000) !important;
  background: linear-gradient(50deg, #FF9E80, #D50000) !important;
  color: #fff !important;
}

.gradient-amber {
  background: #e65100 !important;
  background: -webkit-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: -o-linear-gradient(50deg, #FFBA3A, #e65100) !important;
  background: linear-gradient(50deg, #FFBA3A, #e65100) !important;
  color: #fff !important;
}

.gradient-gray {
  background: #263238 !important;
  background: -webkit-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: -o-linear-gradient(50deg, #8EF9FE, #263238) !important;
  background: linear-gradient(50deg, #8EF9FE, #263238) !important;
  color: #fff !important;
}

.gradient-teal {
  background: #00796B !important;
  background: -webkit-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: -o-linear-gradient(50deg, #A9DC7B, #00796B) !important;
  background: linear-gradient(50deg, #A9DC7B, #00796B) !important;
  color: #fff !important;
}

.gradient-brown {
  background: #5D4037 !important;
  background: -webkit-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: -o-linear-gradient(50deg, #FA9151, #5D4037) !important;
  background: linear-gradient(50deg, #FA9151, #5D4037) !important;
  color: #fff !important;
}

.gradient-lime {
  background: #827717 !important;
  background: -webkit-linear-gradient(50deg, #FDD819, #827717) !important;
  background: -o-linear-gradient(50deg, #FDD819, #827717) !important;
  background: linear-gradient(50deg, #FDD819, #827717) !important;
  color: #fff !important;
}

.app[dir=rtl].fixed-sidenav .footer {
  float: left;
}
.app[dir=rtl].fixed-footer .footer {
  right: auto;
  left: 0;
}
.app[dir=rtl] .info-content .close-btn {
  right: auto !important;
  left: 8px;
}
.app[dir=rtl] .options-icon {
  left: -4px;
}
.app[dir=rtl] .menu-item .mat-button .menu-icon {
  margin-left: 12px;
}
.app[dir=rtl] .menu-expand-icon {
  right: auto;
  left: 10px;
}
.app[dir=rtl] .sub-menu .sub-menu .mat-button {
  padding-right: 40px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 60px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 80px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 100px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 120px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 140px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 160px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 180px;
}
.app[dir=rtl] .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .mat-button {
  padding-right: 200px;
}
.app[dir=rtl] .sub-menu .mat-button {
  padding-right: 20px;
}
.app[dir=rtl].compact .menu-item .mat-button .menu-icon {
  margin-left: 0;
}
.app[dir=rtl].compact .sub-menu .mat-button {
  padding-right: 0 !important;
}
.app[dir=rtl].mini .menu-item .mat-button .menu-icon {
  margin-left: 0;
}
.app[dir=rtl].mini .sub-menu .mat-button {
  padding-right: 0 !important;
}
.app[dir=rtl].mini .menu-expand-icon {
  right: auto;
  left: 0px;
}
.app[dir=rtl] .horizontal-menu-item .horizontal-menu-icon {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .horizontal-sub-menu .horizontal-sub-menu {
  left: auto;
  right: 100%;
}
.app[dir=rtl].horizontal-menu.compact .horizontal-menu-item .horizontal-sub-menu .mat-button .horizontal-menu-icon {
  margin-right: 0;
  margin-left: 10px;
}
.app[dir=rtl] .back-to-top {
  right: auto;
  left: 20px;
}
.app[dir=rtl] .messages img {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .messages img, .app[dir=rtl] .messages .row-1 {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .messages span.text {
  text-align: right;
}
.app[dir=rtl] .flags img {
  margin-right: 0;
  margin-left: 8px;
}
.app[dir=rtl] .tile {
  text-align: left;
}
.app[dir=rtl] .tile .mat-icon {
  left: auto;
  right: 32px;
}
.app[dir=rtl] .user-content .info-list span {
  padding-left: 0;
  padding-right: 8px;
}
.app[dir=rtl] .ql-editor.ql-blank::before {
  right: 6px;
}
.app[dir=rtl] .ql-editor {
  text-align: right;
}
.app[dir=rtl] .chat-status-icon {
  margin-right: 0;
  margin-left: 6px;
}
.app[dir=rtl] .chat-sidenav, .app[dir=rtl] .mailbox-sidenav {
  border-right: none;
  border-left: 1px solid transparent;
}
.app[dir=rtl] .cal-month-view .cal-day-number {
  float: left;
  margin-right: 0;
  margin-left: 15px;
}
.app[dir=rtl] .cal-month-view .cal-day-badge {
  margin-left: 0;
  margin-right: 10px;
}
.app[dir=rtl] .mat-dialog-title {
  text-align: right;
}
.app[dir=rtl] .favorites-container:after {
  right: auto;
  left: -14px;
}
.app[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
.app[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}